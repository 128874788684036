import { Injectable } from '@angular/core';
import axios from 'axios';
import { mutation, query } from 'gql-query-builder';
import { Entity, TypeData } from '../model/entity.model';
import { ClientService } from './client.service';
import { environment } from '../../environments/environment';

const {uri, headers} = environment.hasura

@Injectable({
  providedIn: 'root',
})
export class CrudService {
  constructor(private client: ClientService) {}
  public async queryEntity(entity: Entity, variables?: Variables) {
    const response = await axios.post(
      uri,
      query({
        operation: `${this.client.selectedClient.id}_${entity?.id}`,
        fields: this.buildFields(entity?.data || [], variables),
        variables: this.convertToVariable(
          variables ? variables[entity.id] : {},
          entity
        ),
      }),
      {
        headers,
      }
    );
    return response.data;
  }

  public async insertEntity(entity: Entity, data: any) {
    this.convertToMutation(data, entity.data);
    const response = await axios.post(
      uri,
      mutation({
        operation: `insert_${this.client.selectedClient.id}_${entity.id}_one`,
        variables: {
          object: {
            type: `${this.client.selectedClient.id}_${entity.id}_insert_input!`,
            value: data,
          },
        },
        fields: ['id'],
      }),
      {
        headers,
      }
    );
    console.log(response.data);
  }

  // Make sure data contains all the infomation including ids and nested ids
  public async updateEntity(entity: Entity, id: string, data: any) {
    await this.deleteEntity(entity, id);
    await this.insertEntity(entity, data);
  }

  public async deleteEntity(entity: Entity, id: string) {
    const response = await axios.post(
      uri,
      mutation({
        operation: `delete_${this.client.selectedClient.id}_${entity.id}_by_pk`,
        fields: ['id'],
        variables: {
          id: {
            type: 'uuid!',
            value: id,
          },
        },
      }),
      {
        headers,
      }
    );
    console.log(response.data);
  }

  private buildFields(data: TypeData[], variables?: Variables): any[] {
    const fields: any[] = [];
    data.forEach((ele) => {
      if (ele.type !== 'ARRAY_OF_OBJECT') {
        fields.push(ele.name);
      } else {
        const obj: any = {
          operation: ele.id,
          fields: this.buildFields(ele.data, variables),
          variables: this.convertToVariable(
            variables ? variables[ele.id] : {},
            ele
          ),
        };
        fields.push(obj);
      }
    });
    return fields;
  }

  private convertToMutation(data: any, typeData: TypeData[]) {
    for (let ele of typeData) {
      if (ele.type === 'ARRAY_OF_OBJECT') {
        if (data[ele.name]) {
          for (let arr of data[ele.name]) {
            this.convertToMutation(arr, ele.data);
          }
          data[ele.id] = {
            data: data[ele.name],
          };
          delete data[ele.name];
        }
      }
    }
  }

  private convertToVariable(variable: Variable, data: TypeData | Entity): any {
    const obj: any = {};
    if (variable?.distinct_on) {
      obj[`${data.id}_distinct_on`] = {
        name: 'distinct_on',
        type: `[${this.client.selectedClient.id}_${data.id}_select_column!]`,
        value: variable.distinct_on,
      };
    }

    if (variable?.limit) {
      obj[`${data.id}_limit`] = {
        name: 'limit',
        type: `Int`,
        value: variable.limit,
      };
    }

    if (variable?.offset) {
      obj[`${data.id}_offset`] = {
        name: 'offset',
        type: `Int`,
        value: variable.offset,
      };
    }

    if (variable?.order_by) {
      obj[`${data.id}_order_by`] = {
        name: 'order_by',
        type: `[${this.client.selectedClient.id}_${data.id}_order_by!]`,
        value: variable.order_by,
      };
    }

    if (variable?.where) {
      this.convertToWhere(variable.where, data);
      obj[`${data.id}_where`] = {
        name: 'where',
        type: `${this.client.selectedClient.id}_${data.id}_bool_exp`,
        value: variable.where,
      };
    }
    return obj;
  }
  private convertToWhere(where: Where, data: TypeData | Entity) {
    for (const ele of data.data) {
      if (ele.type === 'ARRAY_OF_OBJECT') {
        if (where[ele.id]) {
          where[ele.id] = where[ele.id];
          // delete where[ele.name];
          this.convertToWhere(where, ele);
        }
      }
    }
  }
}

type Variables = {
  [k: string]: Variable;
};

type Variable = {
  distinct_on?: string;
  limit?: number;
  offset?: number;
  order_by?: OrderBy;
  where?: Where;
};

type OrderBy = {
  [k: string]: 'asc' | 'desc';
};

type Where = {
  [k: string]: Operator | Where;
};

type Operator = {
  _eq?: any;
};

// this.crud.queryEntity(value[0], {
//   sample_test_MTA0ND: {
//     limit: 2,
//     order_by: {
//       id_auto_increment: 'desc',
//     },
//   },
//   items_NjA2OD: {
//     limit: 2,
//     order_by: {
//       name: 'desc',
//     },
//   },
// });
// this.crud.insertEntity(value[0], {
//   name: 'testing five 1',
//   items_NjA2OD: [
//     {
//       name: 'inner 1 new 1 1',
//       types_MTEwNz: [
//         {
//           name: 'type 1 1',
//         },
//         {
//           name: 'type 2 1',
//         },
//       ],
//     },
//     {
//       name: 'inner 2 new 1 1',
//       types_MTEwNz: [
//         {
//           name: 'type 3 1',
//         },
//         {
//           name: 'type 4 1',
//         },
//       ],
//     },
//   ],
// });
