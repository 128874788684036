export const environment = {
  firebaseConfig: {
    apiKey: 'AIzaSyCcfXSrfgdIkQKML4hTh4KPZ82avlOCWr4',
    authDomain: 'olympus-422514.firebaseapp.com',
    projectId: 'olympus-422514',
    storageBucket: 'olympus-422514.appspot.com',
    messagingSenderId: '352721116805',
    appId: '1:352721116805:web:597d5a3ad4c3c2c2e653ec',
    measurementId: 'G-B7QVRB46V6',
  },
  hasura: {
    uri: 'https://feasible-deer-81.hasura.app/v1/graphql',
    headers: {
      'x-hasura-admin-secret':
        '91vV4P4ARScOhcRs7QhSXg807hc2ADOr63thc6HKRS0tHsCRvPZx52dyg7S7PjCW',
    },
  },
};