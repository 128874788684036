import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Sections } from '../../../model/page.model';
import { EntityService } from '../../../services/entity.service';
import { PageService } from '../../../services/page.service';
import { UiSharedServiceService } from '../ui-shared-service.service';

@Component({
  selector: 'app-components',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './components.component.html',
})
export class ComponentsComponent {
  @Input() section: Sections = {
    name: 'default',
    type: 'section',
    id: 'default',
    styles: 'flex justify-between flex-wrap flex-grow',
    hidden: {
      type: 'static',
      entity: '',
      entityVariable: '',
      allowedType: 'BOOLEAN',
      value: false,
    },
    components: [],
  };
  @Output() sectionChange = new EventEmitter<Sections>();
  @Input() selectedSectionIndex: number[] = [];
  @Output() selectedSectionIndexChange = new EventEmitter<number[]>();
  @Input() selectedComponentIndex: number = -1;
  @Output() selectedComponentIndexChange = new EventEmitter<number[]>();

  @Input() indexForSectionsInPage: number = -1;
  @ViewChildren('container', { read: ViewContainerRef })
  containerRef!: QueryList<ViewContainerRef>;
  @Input() containerRefsAdded: ViewContainerRef[] = [];
  @Output() containerRefsAddedChange = new EventEmitter<ViewContainerRef[]>();

  @Input() index: number = 0;
  @Input() mainSectionIndex: number = -1;
  incrementalIndex = 0;
  @Output() indexingChange = new EventEmitter<number>();
  ngOnInit() {
    this.incrementalIndex = JSON.parse(JSON.stringify(this.index));
    if (this.indexForSectionsInPage == -1) {
      const addition = this.index;
      this.indexingChange.emit(addition);
    }
  }
  changeInIndex(index: number) {
    this.index = index;
    this.indexingChange.emit(index);
  }
  // for recursive action
  container: any[] = [];

  @ViewChildren(ComponentsComponent)
  childComponents: QueryList<ComponentsComponent> | undefined;
  async addContainerRefs() {
    this.container = [];
    await this.childComponents
      ?.toArray()
      .forEach((childComponent: ComponentsComponent) => {
        childComponent.addContainerRefs();
      });

    var containerIndex = 0;
    var containerRefIndex = 0;
    var containerRefsToBeAdded: any = [];
    for (let i = 0; i < this.section.components.length; i++) {
      if (this.section.components[i].type == 'section') {
        if (this.container[containerIndex])
          containerRefsToBeAdded = [
            ...containerRefsToBeAdded,
            ...this.container[containerIndex],
          ];
        containerIndex++;
      } else {
        containerRefsToBeAdded.push(
          this.containerRef.toArray()[containerRefIndex]
        );
        containerRefIndex++;
      }
    }

    this.containerRefsAdded = containerRefsToBeAdded;

    this.containerRefsAddedChange.emit(this.containerRefsAdded);

    return true;
  }
  addContainerFromRef(container: any) {
    this.container.push(container);
  }

  // for selected Border
  highlightSectionFlag: boolean[] = [];
  async highlightSectionFlagFunction() {
    if (this.selectedSectionIndex[0] !== this.mainSectionIndex) {
      this.highlightSectionFlag = [false];
      await this.childComponents
        ?.toArray()
        .forEach(async (childComponent: ComponentsComponent) => {
          await childComponent.highlightSectionFlagFunction();
        });
      return false;
    }
    this.highlightSectionFlag = [];
    await this.childComponents
      ?.toArray()
      .forEach(async (childComponent: ComponentsComponent) => {
        this.highlightSectionFlag.push(
          await childComponent.highlightSectionFlagFunction()
        );
      });
    if (
      this.selectedSectionIndex[this.incrementalIndex ? 1 : 0] ==
      (this.indexForSectionsInPage == -1
        ? this.incrementalIndex
        : this.indexForSectionsInPage)
    ) {
      return true;
    } else if (
      this.incrementalIndex !== 0 &&
      this.incrementalIndex > this.selectedSectionIndex[1]
    ) {
      return false;
    } else if (
      this.incrementalIndex !== 0 &&
      this.incrementalIndex < this.selectedSectionIndex[1]
    ) {
      return this.recersiveNumbersForSections(
        this.incrementalIndex,
        this.section
      ).flag;
    }
    return false;
  }
  recersiveNumbersForSections(matchNumber: number, sections: any) {
    if (matchNumber == this.selectedSectionIndex[1]) {
      const returnData = { matchNumber: matchNumber, flag: true };
      return returnData;
    }
    for (let i = 0; i < sections.components.length; i++) {
      if (sections.components[i].type == 'section') {
        matchNumber++;
        var returnedData = this.recersiveNumbersForSections(
          matchNumber,
          sections.components[i]
        );
        matchNumber = returnedData.matchNumber;
        if (returnedData.flag) {
          const returnData = { matchNumber: matchNumber, flag: true };
          return returnData;
        }
      }
    }
    const returnData = { matchNumber: matchNumber, flag: false };
    return returnData;
  }
  sectionNumberIncrement(index: number) {
    if (this.selectedSectionIndex[0] !== this.mainSectionIndex) {
      return 0;
    }
    var sectionNumber = 0;
    for (let i = 0; i < index; i++) {
      if (this.section.components[i].type == 'section') {
        sectionNumber++;
      }
    }
    return sectionNumber;
  }
  selectedComponentFlagFunction(componentIndex: number) {
    var indexing: number = 0;
    if (this.indexForSectionsInPage == -1) {
      return (
        this.selectedComponentIndex ==
          this.recersiveForNumberOfComponentsInNestedSections(
            this.section,
            indexing,
            componentIndex
          ) +
            this.compoundComponentNumber[this.selectedSectionIndex[1] - 1] &&
        this.selectedSectionIndex[0] == this.mainSectionIndex &&
        this.selectedSectionIndex[1] == this.incrementalIndex
      );
    } else {
      return (
        this.selectedComponentIndex ==
          this.recersiveForNumberOfComponentsInNestedSections(
            this.section,
            indexing,
            componentIndex
          ) &&
        this.selectedSectionIndex[0] == this.mainSectionIndex &&
        this.selectedSectionIndex[1] == -1
      );
    }
  }
  recersiveForNumberOfComponentsInNestedSections(
    sections: Sections,
    indexing: number,
    componentIndex: number
  ) {
    for (let j = 0; j < componentIndex; j++) {
      if (sections.components[j].type == 'component') {
        indexing++;
      } else {
        indexing = this.recersiveForNumberOfComponentsInNestedSections(
          sections.components[j],
          indexing,
          sections.components[j].components.length
        );
      }
    }
    return indexing;
  }
  // seelcted component
  recersiveForNumberOfComponentsInNestedSectionsAsArray(
    sections: Sections,
    indexing: number
  ) {
    var sectionCompoundComponentNumber: any[] = [];
    for (let j = 0; j < sections.components.length; j++) {
      if (sections.components[j].type == 'component') {
        indexing++;
      } else {
        sectionCompoundComponentNumber.push(indexing);
        const returnedData =
          this.recersiveForNumberOfComponentsInNestedSectionsAsArray(
            sections.components[j],
            indexing
          );
        sectionCompoundComponentNumber = [
          ...sectionCompoundComponentNumber,
          ...returnedData.sectionCompoundComponentNumber,
        ];
        indexing = returnedData.indexing;
      }
    }
    return {
      sectionCompoundComponentNumber: sectionCompoundComponentNumber,
      indexing: indexing,
    };
  }

  // getting the value of number of components present in the previous sections
  compoundComponentNumber: number[] = [];
  async getCompoundComponentNumber(
    arrayOfMainSectionComponentsBySection: number[] = []
  ) {
    var sectionCompoundComponentNumber: any[] = [];
    var indexing = 0;
    if (this.indexForSectionsInPage !== -1) {
      sectionCompoundComponentNumber =
        await this.recersiveForNumberOfComponentsInNestedSectionsAsArray(
          this.section,
          indexing
        ).sectionCompoundComponentNumber;
      this.compoundComponentNumber = sectionCompoundComponentNumber;
    } else {
      this.compoundComponentNumber = arrayOfMainSectionComponentsBySection;
    }
    await this.childComponents
      ?.toArray()
      .forEach((childComponent: ComponentsComponent, index: number) => {
        childComponent.getCompoundComponentNumber(this.compoundComponentNumber);
      });
  }

  // not required in development also have to remove all styles like this in the end of saving styles

  //! needs to be removed in prod but needed for development
  returnOnlyStyle(data: any) {
    //? right now only usefull for gap
    var style = '';
    // gap
    const gapPxRegex = /gap-\[(\d+)px\]/;
    const matcheGap = data.match(gapPxRegex);
    if (matcheGap && matcheGap[1]) {
      style = style + ' ' + 'gap: ' + matcheGap[1] + 'px;';
    }
    // left padding
    const paddingLeftPxRegex = /pl-\[(\d+)px\]/;
    const matcheLeftPadding = data.match(paddingLeftPxRegex);
    if (matcheLeftPadding && matcheLeftPadding[1]) {
      style = style + ' ' + 'padding-left: ' + matcheLeftPadding[1] + 'px;';
    }
    // right padding
    const paddingRightPxRegex = /pr-\[(\d+)px\]/;
    const matcheRightPadding = data.match(paddingRightPxRegex);
    if (matcheRightPadding && matcheRightPadding[1]) {
      style = style + ' ' + 'padding-right: ' + matcheRightPadding[1] + 'px;';
    }
    // top padding
    const paddingTopPxRegex = /pt-\[(\d+)px\]/;
    const matcheTopPadding = data.match(paddingTopPxRegex);
    if (matcheTopPadding && matcheTopPadding[1]) {
      style = style + ' ' + 'padding-top: ' + matcheTopPadding[1] + 'px;';
    }
    // bottom padding
    const paddingBottomPxRegex = /pb-\[(\d+)px\]/;
    const matcheBottomPadding = data.match(paddingBottomPxRegex);
    if (matcheBottomPadding && matcheBottomPadding[1]) {
      style = style + ' ' + 'padding-bottom: ' + matcheBottomPadding[1] + 'px;';
    }
    // left margin
    const marginLeftPxRegex = /ml-\[(\d+)px\]/;
    const matcheLeftmargin = data.match(marginLeftPxRegex);
    if (matcheLeftmargin && matcheLeftmargin[1]) {
      style = style + ' ' + 'margin-left: ' + matcheLeftmargin[1] + 'px;';
    }
    // right margin
    const marginRightPxRegex = /mr-\[(\d+)px\]/;
    const matcheRightmargin = data.match(marginRightPxRegex);
    if (matcheRightmargin && matcheRightmargin[1]) {
      style = style + ' ' + 'margin-right: ' + matcheRightmargin[1] + 'px;';
    }
    // top margin
    const marginTopPxRegex = /mt-\[(\d+)px\]/;
    const matcheTopmargin = data.match(marginTopPxRegex);
    if (matcheTopmargin && matcheTopmargin[1]) {
      style = style + ' ' + 'margin-top: ' + matcheTopmargin[1] + 'px;';
    }
    // bottom margin
    const marginBottomPxRegex = /mb-\[(\d+)px\]/;
    const matcheBottommargin = data.match(marginBottomPxRegex);
    if (matcheBottommargin && matcheBottommargin[1]) {
      style = style + ' ' + 'margin-bottom: ' + matcheBottommargin[1] + 'px;';
    }
    // background color
    const backgroundColorRegex: RegExp = /bg-\[([^\]]+)\]/;
    const backgroundColor = data.match(backgroundColorRegex);
    if (backgroundColor && backgroundColor[1]) {
      style = style + ' ' + 'background-color: ' + backgroundColor[1] + ';';
    }
    return style;
  }
  //! needs to be removed in prod but needed for development

  constructor(
    private entityService: EntityService,
    private pageService: PageService,
    public uiSharedService: UiSharedServiceService
  ) {}
  returnHiddenFlag(index: number) {
    if (this.section.components[index].hidden.type == 'dynamic') {
      if (this.section.components[index].hidden.entity == 'local_variable') {
        return this.uiSharedService.selectedPage.localVariable[
          this.section.components[index].hidden.entityVariable
        ];
      } else {
        for (
          let i = 0;
          i < this.pageService.newEntityTransformedListForSelected.length;
          i++
        ) {
          if (
            this.pageService.newEntityTransformedListForSelected[i].id ==
            this.section.components[index].hidden.entity
          ) {
            return this.pageService.newEntityTransformedListForSelected[i].data[
              this.section.components[index].hidden.entityVariable
            ];
          }
        }
      }
    }
    return true;
  }
}
