"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var OperationType;
(function (OperationType) {
  OperationType["Mutation"] = "mutation";
  OperationType["Query"] = "query";
  OperationType["Subscription"] = "subscription";
})(OperationType || (OperationType = {}));
exports.default = OperationType;
