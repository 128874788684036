import { Injectable, inject } from '@angular/core';
import {
  Auth,
  User,
  browserLocalPersistence,
  browserSessionPersistence,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
  user,
} from '@angular/fire/auth';
import { Observable, map } from 'rxjs';
import { ClientService } from './client.service';
import { Router } from '@angular/router';

// import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth: Auth = inject(Auth);
  public user: User | null = null;

  public baseCollectionPath = '';

  constructor(
    private clientService: ClientService,
    private router: Router // private http: HttpClient
  ) {
    // this.signOut();
    user(this.auth).subscribe((user: any) => {
      this.user = user;
      user?.getIdTokenResult().then(async (tokenResult: any) => {
        console.log(tokenResult.claims);
        const { clientId } = tokenResult.claims;
        this.clientService.selectedClient = await this.clientService.queryById(
          clientId as string
        );
        this.baseCollectionPath = ('client/' + clientId) as string;
        console.log('yo');
        this.router.navigate(['ui-editor']);
      });
    });
  }

  public isAuthenticated(): Observable<boolean> {
    return user(this.auth).pipe(
      map((user) => {
        if (user) {
          // const auth = getAuth();
          // user.getIdToken()
          //   // .then(getIdTokenResult)
          //   .then((tokenResult) => {
          //     const token = tokenResult;
          //     console.log(token);
          //     const url = 'https://pegasus-api-test-9n1sj8cn.ue.gateway.dev/auth/signup'; // Replace with your API endpoint
          //     this.makePostRequest(url, null, token)
          //       .subscribe(response => {
          //         console.log('Success!', response);
          //       }, error => {
          //         console.error('Error!', error);
          //       });
          //   })
          //   .catch((error) => {
          //     console.error("Error fetching ID token:", error);
          //   });
        }
        return user !== null;
      })
    );
  }

  // makePostRequest(url: string, data: any, key: string) {
  //   const apiKey = key; // Replace with your actual API key
  //   const headers = new HttpHeaders({
  //     'Access-Control-Allow-Origin': '*',
  //     'Authorization': `Bearer ${apiKey}`,
  //     'Content-Type': 'application/json' // Adjust based on your request body
  //   });

  //   return this.http.post(url, data, { headers });
  // }

  public async signInWithEmail(
    email: string,
    password: string,
    remember: boolean = true
  ) {
    await this.auth.setPersistence(
      remember ? browserLocalPersistence : browserSessionPersistence
    );
    await signInWithEmailAndPassword(this.auth, email, password);
  }

  public async signOut() {
    await this.auth.signOut();
  }

  public async sendPasswordResetEmail(email: string) {
    await sendPasswordResetEmail(this.auth, email);
  }

  public async updatePassword(password: string) {
    if (this.user) {
      await updatePassword(this.user, password);
    }
  }
}
