<!-- 
<div class="w-full h-full flex flex-col">
    <div class="w-full h-[50px] bg-[#332D2C] flex justify-between px-[10px] items-center border-b border-solid border-[#cccccc]">
        <div class="flex bg-blue-500 text-white text-[20px] px-[10px] h-fit rounded-md cursor-pointer" (click)="navigateToPages()"> Return</div>
        <div class="text-[#cccccc] text-[32px] font-medium"> {{uiSharedService.selectedPage.name}}</div>
        
        <div class="flex bg-blue-500 text-white text-[20px] px-[10px] h-fit rounded-md cursor-pointer" (click)="savePage()"> Save</div>
    </div>
    <div class="flex w-full flex-grow overflow-hidden">
        <div class="h-full w-[250px] min-w-[250px] bg-[#332D2C] overflow-visible z-[90] relative " #leftNav>
            <div class=" pb-[25px] overflow-y-scroll h-full">
            <div class=" flex justify-between  items-center  h-[40px] mx-[10px] mt-[5px] border-b border-t border-solid border-yellow-500" *ngIf="sectionAddFlag"
            > 
                <div class="text-[#cccccc] text-[16px] flex hover:bg-[#222222] cursor-pointer rounded-md"> 
                <input class="bg-[#332D2C]" type="text" placeholder="Section Name"  [(ngModel)]="newSectionName">    
                </div>
                <div class="text-[#cccccc] text-[24px] hover:bg-[#222222] cursor-pointer rounded-md h-fit leading-[24px] px-[10px] pb-[3px]" (click)="addSectionToPage()">
                    
                    <img src="assets/icons/add-section-confirm-icon.svg" alt="My Icon" class="w-[26px] "/>
                </div>
                </div>

            <div class=" flex justify-between  items-center h-[40px] mx-[10px] border-b border-solid border-[#cccccc]"> 
            <div class="text-[#cccccc] text-[20px] flex hover:bg-[#222222] cursor-pointer rounded-md px-[10px]" > 
                <img src="assets/icons/section-main-icon.svg" alt="My Icon" class="w-[26px] mr-[5px]" />
                 Sections </div>
            <div class="text-[#cccccc] text-[24px]  hover:bg-[#222222] cursor-pointer rounded-md h-fit leading-[24px] px-[10px] pb-[3px]" (click)="addSection()">
                <span *ngIf="sectionAddFlag">
                
                    <img src="assets/icons/u-turn-icon.svg" alt="My Icon" class="w-[20px] " />
                </span>
                <span *ngIf="!sectionAddFlag">     
                     <img src="assets/icons/add-section-icon.svg" alt="My Icon" class="w-[26px] " />
                </span>
            </div>
            </div>
            <div *ngIf="leftNavReloadFlag ">
            <div class="ml-[30px] mr-[10px]"
*ngFor="let section of uiSharedService.selectedPage.sections; let i = index;"
> 
        <app-left-nav-components [(section)] = "uiSharedService.selectedPage.sections[i]" [(selectedComponentIndex)]="selectedComponentIndex" (selectedComponentIndexChange)="selectedComponentIndexChanged($event)" [(selectedSectionIndex)]="selectedSectionIndex" (selectedSectionIndexChange)="selectedSectionIndexChanged($event)" (leftNavComponantsShowFlagChangeEmit)="leftNavComponantsShowFlagChange($event)" [indexForSectionsInPage]="i"></app-left-nav-components>
   
    </div></div>
    </div>
            <div *ngIf="leftNavComponantsShowFlag" class="h-full w-[250px] bg-[#151414] overflow-y-scroll absolute top-0 left-[250px] text-white rounded-r-md" #leftNavContent>
                <div class=" flex justify-between  items-center  h-[40px] mx-[10px] mt-[5px] border-b border-t border-solid border-yellow-500"
                > 
                    <div class="text-[#cccccc] text-[16px] flex hover:bg-[#222222] cursor-pointer rounded-md"> 
                    <input class="bg-[#332D2C]" type="text" placeholder="Section Name"  [(ngModel)]="newSubSectionName">    
                    </div>
                    <div class="text-[#cccccc] text-[24px] hover:bg-[#222222] cursor-pointer rounded-md h-fit leading-[24px] px-[10px] pb-[3px]" (click)="addSubSectionToSection()">
                        
                        <img src="assets/icons/add-section-confirm-icon.svg" alt="My Icon" class="w-[26px] "/>
                    </div>
                    </div>
                <div *ngFor="let component of componentConfig; let i= index" class="text-[#cccccc] px-[10px] h-[40px] flex flex-col justify-center w-full hover:bg-[#222222] rounded-md cursor-pointer" (click)="addComponentToSection(i)">
                {{component.name}}
                </div>
            </div>
        </div> -->
<div
  class="flex-grow flex flex-col p-[10px] z-[80] overflow-x-hidden overflow-y-scroll h-full"
  *ngIf="componentReloadFlag"
>
  <app-components
    *ngFor="let section of uiSharedService.selectedPage.sections; let i = index"
    [style]="returnOnlyStyle(section.styles)"
    class="overflow-y-visible {{ section.styles }} {{
      selectedSectionIndex[0] == i ? 'border border-solid border-red-500' : ''
    }}  {{ returnHiddenFlagForSection(i) ? '' : 'hidden' }}"
    [(containerRefsAdded)]="containers[i]"
    [(section)]="uiSharedService.selectedPage.sections[i]"
    [(selectedComponentIndex)]="selectedComponentIndex"
    [(selectedSectionIndex)]="selectedSectionIndex"
    [mainSectionIndex]="i"
    [indexForSectionsInPage]="i"
  ></app-components>
</div>

<!-- start of right nav  -->
<!-- <div class="h-full w-[250px] min-w-[250px] bg-[#332D2C] overflow-y-scroll p-[10px] text-[#cccccc]">
            <div class="flex flex-col justify-between gap-[100px]" *ngIf="selectedSectionIndex[0] !== -1 && (selectedComponentIndex == -1)">
              <div *ngIf="!onlyIndexChangeOfComponentAndSectionFlag">
                <div class="border-b border-solid border-[#cccccc] w-full text-center"> {{ selectedSection.name}}</div>
                <div class=" border-b border-t border-solid border-red-600">

                    <div class=" border-b border-t border-solid border-yellow-500 rounded-md"> Show:</div>
                    <div class=" flex justify-center mt-[5px]">
                        <div class="px-[5px] w-fit rounded-l-md border border-solid border-[#cccccc] cursor-pointer {{selectedSection.hidden.type == 'static' ? 'bg-black':''}}" (click)="changeSelectedSectionTypeHidden('static')"> Static</div>
                        <div class="px-[5px] w-fit rounded-r-md border border-solid border-[#cccccc] cursor-pointer {{selectedSection.hidden.type == 'dynamic' ? 'bg-black':''}}" (click)="changeSelectedSectionTypeHidden('dynamic')"> Dynamic</div>
                    </div>
                    <div class=" self-center" *ngIf="selectedSection.hidden.type == 'dynamic'">
                          <div class="mt-[5px] " > Entity:</div>
                            <select class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc] w-[200px]" [(ngModel)]="selectedSection.hidden.entity" (change)="dynamicEntityChangedHiddenForSection()">
                                <option value="">Select Entity</option>
                                <option value="local_variable">LOCAL VARIABLE</option>
                                <option value="global_variable">GLOBAL VARIABLE</option>
                                <option *ngFor="let entity of uiSharedService.newEntityTransformedListForSelected" [value]="entity.id"> {{ entity.name}}</option>
                                <option *ngFor="let entity of uiSharedService.localEntityList" [value]="entity.id"> {{ entity.name}}</option>
                                <option *ngFor="let entity of pageService.globalEntityList" [value]="entity.id"> {{ entity.name}}</option>
                            </select>
    
                            <div class="mt-[5px] " *ngIf="selectedSection.hidden.entity == 'local_variable'">Local Variable:</div>
                            <div class="mt-[5px] " *ngIf="selectedSection.hidden.entity == 'global_variable'">Global Variable:</div>
    
          
                                <select *ngIf="selectedSection.hidden.entity == 'local_variable'" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc]" [(ngModel)]="selectedSection.hidden.entityVariable" (change)="dynamicVaraibleChanged()">
                                    <ng-container *ngFor="let key of getKeys(uiSharedService.selectedPage.localVariable)">
                                        <option *ngIf="getType(uiSharedService.selectedPage.localVariable[key]) == selectedSection.hidden.allowedType " [ngValue]="key">
                                          {{ key }}
                                        </option>
                                      </ng-container>
                                </select>
                                <select *ngIf="selectedSection.hidden.entity == 'global_variable'" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc]" [(ngModel)]="selectedSection.hidden.entityVariable" (change)="dynamicVaraibleChanged()">
                                       <ng-container *ngFor="let key of getKeys(globalVariablesService.globalVariable)">
                                           <option *ngIf="getType(uiSharedService.selectedPage.localVariable[key]) == selectedSection.hidden.allowedType " [ngValue]="key">
                                             {{ key }}
                                           </option>
                                         </ng-container>
                                   </select>
                        
                            <div class="mt-[5px] " *ngIf="selectedSection.hidden.entity !== '' && selectedSection.hidden.entity !== 'local_variable' && selectedSection.hidden.entity !== 'global_variable'"> Variable:</div>
                            <ng-container *ngFor="let entity of uiSharedService.newEntityTransformedListForSelected" class="">
                            <select *ngIf="entity.id == selectedSection.hidden.entity" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc]" [(ngModel)]="selectedSection.hidden.entityVariable" (change)="dynamicVaraibleChanged()">
                               <ng-container *ngFor="let key of getKeys(entity.data)">
                                    <option *ngIf="getType(entity.data[key]) == selectedSection.hidden.allowedType && key !== 'id' && key !== 'id_auto_increment'"  [ngValue]="key">
                                      {{ key }}
                                    </option>
                                  </ng-container>
                            </select>
                            </ng-container>
                            <ng-container *ngFor="let entity of uiSharedService.localEntityList" class="">
                                <select *ngIf="entity.id == selectedSection.hidden.entity" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc] w-[200px]" [(ngModel)]="selectedSection.hidden.entityVariable" (change)="dynamicVaraibleChanged()">
                                   <ng-container *ngFor="let key of getKeys(entity.data)">
                                        <option *ngIf="getType(entity.data[key]) == selectedSection.hidden.allowedType && key !== 'id' && key !== 'id_auto_increment'"  [ngValue]="key">
                                          {{ key }}
                                        </option>
                                      </ng-container>
                                </select>
                                </ng-container>
                                <ng-container *ngFor="let entity of pageService.globalEntityList" class="">
                                    <select *ngIf="entity.id == selectedSection.hidden.entity" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc] w-[200px]" [(ngModel)]="selectedSection.hidden.entityVariable" (change)="dynamicVaraibleChanged()">
                                       <ng-container *ngFor="let key of getKeys(entity.data)">
                                            <option *ngIf="getType(entity.data[key]) == selectedSection.hidden.allowedType && key !== 'id' && key !== 'id_auto_increment'"  [ngValue]="key">
                                              {{ key }}
                                            </option>
                                          </ng-container>
                                    </select>
                                    </ng-container>
                            </div>
                   </div>
                <div class="p-[5px] "> Direction:</div>
                <div class="p-[5px] flex justify-center gap-[20px]">
                    <div class="p-[5px] rounded-md border border-solid border-[#cccccc] cursor-pointer {{!sectionStyleIncludes('flex-col') ? 'bg-black':''}}" (click)="changeSelectedSectionStylee('flex-col')">
                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                            <g id="-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Editor" transform="translate(-192.000000, -240.000000)" fill-rule="nonzero">
                                    <g id="column_fill" transform="translate(192.000000, 240.000000)">
                                        <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z" id="MingCute" fill-rule="nonzero">
                        
                        </path>
                                        <path d="M8,3 L8,21 L5,21 C3.89543,21 3,20.1046 3,19 L3,5 C3,3.89543 3.89543,3 5,3 L8,3 Z M14,3 L14,21 L10,21 L10,3 L14,3 Z M19,3 C20.1046,3 21,3.89543 21,5 L21,19 C21,20.1046 20.1046,21 19,21 L16,21 L16,3 L19,3 Z"  fill="#cccccc">
                        
                        </path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div class="p-[5px] rounded-md border border-solid border-[#cccccc] cursor-pointer {{sectionStyleIncludes('flex-col') ? 'bg-black':''}}" (click)="changeSelectedSectionStylee('flex-col')">
                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                            <g id="-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Editor" transform="translate(-48.000000, -48.000000)" fill-rule="nonzero">
                                    <g id="align_left_fill" transform="translate(48.000000, 48.000000)">
                                        <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z" id="MingCute" fill-rule="nonzero">
                        
                        </path>
                                        <path d="M14,17.5 C14.8284,17.5 15.5,18.1716 15.5,19 C15.5,19.7796706 14.9050879,20.4204457 14.1444558,20.4931332 L14,20.5 L4,20.5 C3.17157,20.5 2.5,19.8284 2.5,19 C2.5,18.2203294 3.09488554,17.5795543 3.85553954,17.5068668 L4,17.5 L14,17.5 Z M20,12.5 C20.8284,12.5 21.5,13.1716 21.5,14 C21.5,14.8284 20.8284,15.5 20,15.5 L4,15.5 C3.17157,15.5 2.5,14.8284 2.5,14 C2.5,13.1716 3.17157,12.5 4,12.5 L20,12.5 Z M14,7.5 C14.8284,7.5 15.5,8.17157 15.5,9 C15.5,9.77969882 14.9050879,10.420449 14.1444558,10.4931335 L14,10.5 L4,10.5 C3.17157,10.5 2.5,9.82843 2.5,9 C2.5,8.22030118 3.09488554,7.579551 3.85553954,7.50686655 L4,7.5 L14,7.5 Z M20,2.5 C20.8284,2.5 21.5,3.17157 21.5,4 C21.5,4.77969882 20.9050879,5.420449 20.1444558,5.49313345 L20,5.5 L4,5.5 C3.17157,5.5 2.5,4.82843 2.5,4 C2.5,3.22030118 3.09488554,2.579551 3.85553954,2.50686655 L4,2.5 L20,2.5 Z" fill="#cccccc">
                        
                        </path>
                                    </g>
                                </g>
                            </g>
                        </svg> 
                    </div>
                    </div>
                <div class="p-[5px] "> Align Items:</div>
                <div class="p-[5px] flex justify-around">
                    <div class="p-[5px] rounded-md border border-solid border-[#cccccc] cursor-pointer {{sectionStyleIncludes('justify-start') ? 'bg-black':''}}" (click)="changeSelectedSectionStylee('justify-start')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16"><path d="M 0 0.5 C 0 0.224 0.224 0 0.5 0 L 0.5 0 C 0.776 0 1 0.224 1 0.5 L 1 15.5 C 1 15.776 0.776 16 0.5 16 L 0.5 16 C 0.224 16 0 15.776 0 15.5 Z" fill="currentColor"></path><path d="M 3 7 C 3 5.895 3.895 5 5 5 L 12 5 C 13.105 5 14 5.895 14 7 L 14 9 C 14 10.105 13.105 11 12 11 L 5 11 C 3.895 11 3 10.105 3 9 Z" fill="#cccccc"></path></svg>
                    </div>
                    <div class="p-[5px] rounded-md border border-solid border-[#cccccc] cursor-pointer {{sectionStyleIncludes('justify-center') ? 'bg-black':''}}" (click)="changeSelectedSectionStylee('justify-center')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16"><path d="M 7 0.5 C 7 0.224 7.224 0 7.5 0 L 7.5 0 C 7.776 0 8 0.224 8 0.5 L 8 15.5 C 8 15.776 7.776 16 7.5 16 L 7.5 16 C 7.224 16 7 15.776 7 15.5 Z" fill="currentColor"></path><path d="M 1 7 C 1 5.895 1.895 5 3 5 L 12 5 C 13.105 5 14 5.895 14 7 L 14 9 C 14 10.105 13.105 11 12 11 L 3 11 C 1.895 11 1 10.105 1 9 Z" fill="#cccccc"></path></svg>  </div>
                    <div class="p-[5px] rounded-md border border-solid border-[#cccccc] cursor-pointer {{sectionStyleIncludes('justify-end') ? 'bg-black':''}}" (click)="changeSelectedSectionStylee('justify-end')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16"><path d="M 13 0.5 C 13 0.224 13.224 0 13.5 0 L 13.5 0 C 13.776 0 14 0.224 14 0.5 L 14 15.5 C 14 15.776 13.776 16 13.5 16 L 13.5 16 C 13.224 16 13 15.776 13 15.5 Z" fill="currentColor"></path><path d="M 0 7 C 0 5.895 0.895 5 2 5 L 9 5 C 10.105 5 11 5.895 11 7 L 11 9 C 11 10.105 10.105 11 9 11 L 2 11 C 0.895 11 0 10.105 0 9 Z" fill="#cccccc"></path></svg>    </div>
                    <div class="p-[5px] rounded-md border border-solid border-[#cccccc] cursor-pointer {{sectionStyleIncludes('justify-between') ? 'bg-black':''}}" (click)="changeSelectedSectionStylee('justify-between')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17px" height="17px" viewBox="0 0 15 15" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4999 0.999994C14.2237 0.999994 13.9999 1.22385 13.9999 1.49999L13.9999 5.99995L9.99992 5.99995C9.44764 5.99995 8.99993 6.44766 8.99993 6.99994L8.99993 7.99994C8.99993 8.55222 9.44764 8.99993 9.99992 8.99993L13.9999 8.99993L13.9999 13.4999C13.9999 13.776 14.2237 13.9999 14.4999 13.9999C14.776 13.9999 14.9999 13.776 14.9999 13.4999L14.9999 1.49999C14.9999 1.22385 14.776 0.999994 14.4999 0.999994ZM4.99996 5.99995L0.999992 5.99995L0.999992 1.49999C0.999992 1.22385 0.776136 0.999994 0.499996 0.999994C0.223856 0.999994 -9.7852e-09 1.22385 -2.18557e-08 1.49999L4.07279e-07 13.4999C3.95208e-07 13.776 0.223855 13.9999 0.499996 13.9999C0.776136 13.9999 0.999992 13.776 0.999992 13.4999L0.999992 8.99993L4.99996 8.99993C5.55224 8.99993 5.99995 8.55222 5.99995 7.99993L5.99995 6.99994C5.99995 6.44766 5.55224 5.99995 4.99996 5.99995Z" fill="#cccccc"/>
                          </svg>       </div>
                          <div class="p-[5px] rounded-md border border-solid border-[#cccccc] cursor-pointer {{sectionStyleIncludes('justify-around') ? 'bg-black':''}}" (click)="changeSelectedSectionStylee('justify-around')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17px" height="17px" viewBox="0 0 15 15" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4999 0.999992C14.2237 0.999992 13.9999 1.22385 13.9999 1.49999L13.9999 13.4999C13.9999 13.776 14.2237 13.9999 14.4999 13.9999C14.776 13.9999 14.9999 13.776 14.9999 13.4999L14.9999 1.49999C14.9999 1.22385 14.776 0.999992 14.4999 0.999992ZM0.499996 0.999992C0.223856 0.999992 -9.78509e-09 1.22385 -2.18556e-08 1.49999L4.07279e-07 13.4999C3.95208e-07 13.776 0.223855 13.9999 0.499996 13.9999C0.776136 13.9999 0.999992 13.776 0.999992 13.4999L0.999992 1.49999C0.999992 1.22385 0.776136 0.999992 0.499996 0.999992ZM1.99998 6.99994C1.99998 6.44766 2.44769 5.99995 2.99998 5.99995L5.99995 5.99995C6.55223 5.99995 6.99994 6.44766 6.99994 6.99994L6.99994 7.99993C6.99994 8.55221 6.55223 8.99992 5.99995 8.99992L2.99998 8.99992C2.4477 8.99992 1.99998 8.55221 1.99998 7.99993L1.99998 6.99994ZM8.99993 5.99995C8.44765 5.99995 7.99993 6.44766 7.99993 6.99994L7.99993 7.99993C7.99993 8.55221 8.44765 8.99992 8.99993 8.99992L11.9999 8.99992C12.5522 8.99992 12.9999 8.55221 12.9999 7.99993L12.9999 6.99994C12.9999 6.44766 12.5522 5.99995 11.9999 5.99995L8.99993 5.99995Z" fill="#cccccc"/>
                              </svg>
                          </div>
                </div>
                 <div class=" flex justify-center mt-[5px]">
                    <div class="p-[5px] w-fit rounded-l-md border border-solid border-[#cccccc] cursor-pointer {{sectionStyleIncludes('flex-wrap') ? 'bg-black':''}}" (click)="changeSelectedSectionStylee('flex-wrap')"> Wrap</div>
                    <div class="p-[5px] w-fit rounded-r-md border border-solid border-[#cccccc] cursor-pointer {{!sectionStyleIncludes('flex-wrap') ? 'bg-black':''}}" (click)="changeSelectedSectionStylee('flex-wrap')"> No-Wrap</div>
                </div>
                <div class=" flex justify-center mt-[15px]">
                    <div class="p-[5px] w-fit rounded-md border border-solid border-[#cccccc] cursor-pointer {{sectionStyleIncludes('flex-grow') ? 'bg-black':''}}" (click)="changeSelectedSectionStylee('flex-grow')"> Grow</div>
                   </div>
                  <div class=" mt-[15px] w-full flex justify-center"> <div class="text-[#cccccc] text-[16px] flex hover:bg-[#222222] cursor-pointer w-fit border-b border-t border-solid border-yellow-500 rounded-md overflow-hidden"> 
                    Gap: <input class="bg-black ml-[10px] w-[40px] text-center" type="text" placeholder="Section Name"  [(ngModel)]="gapStyleForSection" (change)="changeSelectedSectionStylee('flex-gap')">    
                    </div>
                    </div>
                    <div class="p-[5px] "> Padding:</div>
                    <div class=" mt-[15px] w-full flex justify-center"> <div class="text-[#cccccc] text-[16px] flex hover:bg-[#222222] cursor-pointer w-fit border-b border-t border-solid border-yellow-500 rounded-md overflow-hidden"> 
                        left: <input class="bg-black ml-[10px] w-[40px] text-center" type="text" placeholder="Section Name"  [(ngModel)]="paddingLeftStyleForSection" (change)="changeSelectedSectionStylee('padding-left')">    
                        </div>
                        </div>
                     <div class=" mt-[15px] w-full flex justify-center"> <div class="text-[#cccccc] text-[16px] flex hover:bg-[#222222] cursor-pointer w-fit border-b border-t border-solid border-yellow-500 rounded-md overflow-hidden"> 
                    right: <input class="bg-black ml-[10px] w-[40px] text-center" type="text" placeholder="Section Name"  [(ngModel)]="paddingRightStyleForSection" (change)="changeSelectedSectionStylee('padding-right')">    
                    </div>
                    </div>
                     <div class=" mt-[15px] w-full flex justify-center"> <div class="text-[#cccccc] text-[16px] flex hover:bg-[#222222] cursor-pointer w-fit border-b border-t border-solid border-yellow-500 rounded-md overflow-hidden"> 
                    top: <input class="bg-black ml-[10px] w-[40px] text-center" type="text" placeholder="Section Name"  [(ngModel)]="paddingTopStyleForSection" (change)="changeSelectedSectionStylee('padding-top')">    
                    </div>
                    </div>
                     <div class=" mt-[15px] w-full flex justify-center"> <div class="text-[#cccccc] text-[16px] flex hover:bg-[#222222] cursor-pointer w-fit border-b border-t border-solid border-yellow-500 rounded-md overflow-hidden"> 
                    bottom: <input class="bg-black ml-[10px] w-[40px] text-center" type="text" placeholder="Section Name"  [(ngModel)]="paddingBottomStyleForSection" (change)="changeSelectedSectionStylee('padding-bottom')">    
                    </div>
                    </div>
                    <div class="p-[5px] "> margin:</div>
                    <div class=" mt-[15px] w-full flex justify-center"> <div class="text-[#cccccc] text-[16px] flex hover:bg-[#222222] cursor-pointer w-fit border-b border-t border-solid border-yellow-500 rounded-md overflow-hidden"> 
                        left: <input class="bg-black ml-[10px] w-[40px] text-center" type="text" placeholder="Section Name"  [(ngModel)]="marginLeftStyleForSection" (change)="changeSelectedSectionStylee('margin-left')">    
                        </div>
                        </div>
                     <div class=" mt-[15px] w-full flex justify-center"> <div class="text-[#cccccc] text-[16px] flex hover:bg-[#222222] cursor-pointer w-fit border-b border-t border-solid border-yellow-500 rounded-md overflow-hidden"> 
                    right: <input class="bg-black ml-[10px] w-[40px] text-center" type="text" placeholder="Section Name"  [(ngModel)]="marginRightStyleForSection" (change)="changeSelectedSectionStylee('margin-right')">    
                    </div>
                    </div>
                     <div class=" mt-[15px] w-full flex justify-center"> <div class="text-[#cccccc] text-[16px] flex hover:bg-[#222222] cursor-pointer w-fit border-b border-t border-solid border-yellow-500 rounded-md overflow-hidden"> 
                    top: <input class="bg-black ml-[10px] w-[40px] text-center" type="text" placeholder="Section Name"  [(ngModel)]="marginTopStyleForSection" (change)="changeSelectedSectionStylee('margin-top')">    
                    </div>
                    </div>
                     <div class=" mt-[15px] w-full flex justify-center"> <div class="text-[#cccccc] text-[16px] flex hover:bg-[#222222] cursor-pointer w-fit border-b border-t border-solid border-yellow-500 rounded-md overflow-hidden"> 
                    bottom: <input class="bg-black ml-[10px] w-[40px] text-center" type="text" placeholder="Section Name"  [(ngModel)]="marginBottomStyleForSection" (change)="changeSelectedSectionStylee('margin-bottom')">    
                    </div>
                    </div>
                    <div class="p-[5px] "> Background:</div>
                    <div class=" mt-[15px] w-full flex justify-center"> <div class="text-[#cccccc] text-[16px] flex hover:bg-[#222222] cursor-pointer w-fit border-b border-t border-solid border-yellow-500 rounded-md overflow-hidden"> 
                        Color: <input class="bg-black ml-[10px] w-[40px] text-center" type="text" placeholder="Section Name"  [(ngModel)]="backgroundColorStyleForSection" (change)="changeSelectedSectionStylee('background-color')">    
                        </div>
                        </div>
</div>
<div class="items-center">
    <div class="">
       <div class=" mb-[10px]"> Shift Position: </div>
       <div class="flex justify-center gap-[10px]" *ngIf="selectedSectionIndexInParent !== -1">
        <div *ngIf="selectedSectionIndexInParent !== 0" class="p-[5px] rounded-md border border-solid border-[#cccccc] cursor-pointer hover:bg-black" (click)="changeSectionUpByOne()">
            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C12.2652 3 12.5196 3.10536 12.7071 3.29289L19.7071 10.2929C20.0976 10.6834 20.0976 11.3166 19.7071 11.7071C19.3166 12.0976 18.6834 12.0976 18.2929 11.7071L13 6.41421V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V6.41421L5.70711 11.7071C5.31658 12.0976 4.68342 12.0976 4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929L11.2929 3.29289C11.4804 3.10536 11.7348 3 12 3Z" fill="#cccccc"/>
                </svg>     </div>
              <div *ngIf="selectedSectionIndexInParent !== (selectedParentSection.components.length -1)" class="p-[5px] rounded-md border border-solid border-[#cccccc] cursor-pointer hover:bg-black" (click)="changeSectionDownByOne()">
                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C12.5523 3 13 3.44772 13 4V17.5858L18.2929 12.2929C18.6834 11.9024 19.3166 11.9024 19.7071 12.2929C20.0976 12.6834 20.0976 13.3166 19.7071 13.7071L12.7071 20.7071C12.3166 21.0976 11.6834 21.0976 11.2929 20.7071L4.29289 13.7071C3.90237 13.3166 3.90237 12.6834 4.29289 12.2929C4.68342 11.9024 5.31658 11.9024 5.70711 12.2929L11 17.5858V4C11 3.44772 11.4477 3 12 3Z" fill="#cccccc"/>
                    </svg>
              </div>
       </div>
       <div class="flex justify-center gap-[10px]" *ngIf="selectedSectionIndexInParent == -1">
        <div *ngIf="selectedSectionIndex[0] !== 0" class="p-[5px] rounded-md border border-solid border-[#cccccc] cursor-pointer hover:bg-black" (click)="changeSectionUpByOne()">
            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C12.2652 3 12.5196 3.10536 12.7071 3.29289L19.7071 10.2929C20.0976 10.6834 20.0976 11.3166 19.7071 11.7071C19.3166 12.0976 18.6834 12.0976 18.2929 11.7071L13 6.41421V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V6.41421L5.70711 11.7071C5.31658 12.0976 4.68342 12.0976 4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929L11.2929 3.29289C11.4804 3.10536 11.7348 3 12 3Z" fill="#cccccc"/>
                </svg>     </div>
              <div *ngIf="selectedSectionIndex[0] !== (uiSharedService.selectedPage.sections.length -1)" class="p-[5px] rounded-md border border-solid border-[#cccccc] cursor-pointer hover:bg-black" (click)="changeSectionDownByOne()">
                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C12.5523 3 13 3.44772 13 4V17.5858L18.2929 12.2929C18.6834 11.9024 19.3166 11.9024 19.7071 12.2929C20.0976 12.6834 20.0976 13.3166 19.7071 13.7071L12.7071 20.7071C12.3166 21.0976 11.6834 21.0976 11.2929 20.7071L4.29289 13.7071C3.90237 13.3166 3.90237 12.6834 4.29289 12.2929C4.68342 11.9024 5.31658 11.9024 5.70711 12.2929L11 17.5858V4C11 3.44772 11.4477 3 12 3Z" fill="#cccccc"/>
                    </svg>
              </div>
       </div>
    </div>
    <div class="text-red-500 text-center border border-solid border-red-500 rounded-md cursor-pointer mt-[20px] mb-[20px]" (click)="deleteSelectedSection()"> Delete </div>
</div>

            </div>
            <div class="" *ngIf="(selectedComponentIndex !== -1)">
                <div class="border-b border-solid border-[#cccccc] w-full text-center" (click)="changeSelectedComponentStyle()"> {{ selectedComponent.name}}</div>
               <div class=" border-b border-t border-solid border-red-600">

                <div class=" border-b border-t border-solid border-yellow-500 rounded-md"> Show:</div>
                <div class=" flex justify-center mt-[5px]">
                    <div class="px-[5px] w-fit rounded-l-md border border-solid border-[#cccccc] cursor-pointer {{selectedComponent.hidden.type == 'static' ? 'bg-black':''}}" (click)="changeSelectedComponentTypeHidden('static')"> Static</div>
                    <div class="px-[5px] w-fit rounded-r-md border border-solid border-[#cccccc] cursor-pointer {{selectedComponent.hidden.type == 'dynamic' ? 'bg-black':''}}" (click)="changeSelectedComponentTypeHidden('dynamic')"> Dynamic</div>
                </div>
                <div class=" self-center" *ngIf="selectedComponent.hidden.type == 'dynamic'">
                      <div class="mt-[5px] " > Entity:</div>
                        <select class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc] w-[200px]" [(ngModel)]="selectedComponent.hidden.entity" (change)="dynamicEntityChangedHidden()">
                            <option value="">Select Entity</option>
                            <option value="local_variable">LOCAL VARIABLE</option>
                            <option *ngFor="let entity of uiSharedService.newEntityTransformedListForSelected" [value]="entity.id"> {{ entity.name}}</option>
                            <option *ngFor="let entity of uiSharedService.localEntityList" [value]="entity.id"> {{ entity.name}}</option>
                            <option *ngFor="let entity of pageService.globalEntityList" [value]="entity.id"> {{ entity.name}}</option>
                        </select>

                        <div class="mt-[5px] " *ngIf="selectedComponent.hidden.entity == 'local_variable'">Local Variable:</div>
                        <div class="mt-[5px] " *ngIf="selectedComponent.hidden.entity == 'global_variable'">Global Variable:</div>

      
                            <select *ngIf="selectedComponent.hidden.entity == 'local_variable'" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc]" [(ngModel)]="selectedComponent.hidden.entityVariable" (change)="dynamicVaraibleChanged()">
                                <ng-container *ngFor="let key of getKeys(uiSharedService.selectedPage.localVariable)">
                                    <option *ngIf="getType(uiSharedService.selectedPage.localVariable[key]) == selectedComponent.hidden.allowedType " [ngValue]="key">
                                      {{ key }}
                                    </option>
                                  </ng-container>
                            </select>
                            <select *ngIf="selectedComponent.hidden.entity == 'global_variable'" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc]" [(ngModel)]="selectedComponent.hidden.entityVariable" (change)="dynamicVaraibleChanged()">
                                <ng-container *ngFor="let key of getKeys(globalVariablesService.globalVariable)">
                                    <option *ngIf="getType(uiSharedService.selectedPage.localVariable[key]) == selectedComponent.hidden.allowedType " [ngValue]="key">
                                      {{ key }}
                                    </option>
                                  </ng-container>
                            </select>
                    
                        <div class="mt-[5px] " *ngIf="selectedComponent.hidden.entity !== '' && selectedComponent.hidden.entity !== 'local_variable' && selectedComponent.hidden.entity !== 'global_variable'"> Variable:</div>
                        <ng-container *ngFor="let entity of uiSharedService.newEntityTransformedListForSelected" class="">
                        <select *ngIf="entity.id == selectedComponent.hidden.entity" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc]" [(ngModel)]="selectedComponent.hidden.entityVariable" (change)="dynamicVaraibleChanged()">
                           <ng-container *ngFor="let key of getKeys(entity.data)">
                                <option *ngIf="getType(entity.data[key]) == selectedComponent.hidden.allowedType && key !== 'id' && key !== 'id_auto_increment'"  [ngValue]="key">
                                  {{ key }}
                                </option>
                              </ng-container>
                        </select>
                        </ng-container>
                        <ng-container *ngFor="let entity of uiSharedService.localEntityList" class="">
                            <select *ngIf="entity.id == selectedComponent.hidden.entity" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc] w-[200px]" [(ngModel)]="selectedComponent.hidden.entityVariable" (change)="dynamicVaraibleChanged()">
                               <ng-container *ngFor="let key of getKeys(entity.data)">
                                    <option *ngIf="getType(entity.data[key]) == selectedComponent.hidden.allowedType && key !== 'id' && key !== 'id_auto_increment'"  [ngValue]="key">
                                      {{ key }}
                                    </option>
                                  </ng-container>
                            </select>
                        </ng-container>
                        <ng-container *ngFor="let entity of pageService.globalEntityList" class="">
                            <select *ngIf="entity.id == selectedComponent.hidden.entity" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc] w-[200px]" [(ngModel)]="selectedComponent.hidden.entityVariable" (change)="dynamicVaraibleChanged()">
                               <ng-container *ngFor="let key of getKeys(entity.data)">
                                    <option *ngIf="getType(entity.data[key]) == selectedComponent.hidden.allowedType && key !== 'id' && key !== 'id_auto_increment'"  [ngValue]="key">
                                      {{ key }}
                                    </option>
                                  </ng-container>
                            </select>
                        </ng-container>
                        </div>
               </div>
                <div class="p-[5px] "> INPUTS:</div>
                <div class=" mt-[5px] w-full flex flex-col justify-center" *ngFor="let componentData of selectedComponent.componentDataBinding; let i = index"> 
                    <ng-container *ngIf="componentData.objectType == 'input'">
                    <div class=" flex justify-center mt-[5px]">
                        <div class="px-[5px] w-fit rounded-l-md border border-solid border-[#cccccc] cursor-pointer {{componentData.type == 'static' ? 'bg-black':''}}" (click)="changeSelectedComponentType('static', i)"> Static</div>
                        <div class="px-[5px] w-fit rounded-r-md border border-solid border-[#cccccc] cursor-pointer {{componentData.type == 'dynamic' ? 'bg-black':''}}" (click)="changeSelectedComponentType('dynamic', i)"> Dynamic</div>
                    </div>
                    <div *ngIf="componentData.type == 'static'" class="text-[#cccccc] text-[16px] flex px-[10px] hover:bg-[#222222] cursor-pointer w-fit border-b border-t border-solid border-yellow-500 rounded-md overflow-hidden"> 
                    {{componentData.key}}:
                     <input *ngIf="componentData.allowedType == 'STRING'" class="bg-black ml-[10px] flex-grow text-center" type="text" placeholder="Input"  [(ngModel)]="componentData.value" (change)="updateChildComponentsByIndex()">    
                     <input *ngIf="componentData.allowedType == 'NUMBER'" class="bg-black ml-[10px] flex-grow text-center" type="number" placeholder="Input"  [(ngModel)]="componentData.value" (change)="updateChildComponentsByIndex()">    
                   <select *ngIf="componentData.allowedType == 'ENUM'" class="bg-black ml-[10px] flex-grow text-center" type="number" placeholder="Input"  [(ngModel)]="componentData.value" (change)="updateChildComponentsByIndex()">
                    <option *ngFor="let data of componentData.allowedData" [value]="data">{{data}}</option>
                   </select>
                    </div>
                    <div class=" self-center" *ngIf="componentData.type == 'dynamic' && componentData.allowedType !== 'ARRAY_OF_OBJECTS'">
                        <div class=" border-b border-t border-solid border-yellow-500 rounded-md"> {{componentData.key}}</div>
                        <div class="mt-[5px] " > Entity:</div>
                        <select class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc] w-[200px]" [(ngModel)]="componentData.entity" (change)="dynamicEntityChanged(i)">
                            <option value="">Select Entity</option>
                            <option value="local_variable">LOCAL VARIABLE</option>
                            <option value="global_variable">GLOBAL VARIABLE</option>
                            <option *ngFor="let entity of uiSharedService.newEntityTransformedListForSelected" [value]="entity.id"> {{ entity.name}}</option>
                            <option *ngFor="let entity of uiSharedService.localEntityList" [value]="entity.id"> {{ entity.name}}</option>
                            <option *ngFor="let entity of pageService.globalEntityList" [value]="entity.id"> {{ entity.name}}</option>
                        </select>

                        <div class="mt-[5px] " *ngIf="componentData.entity == 'local_variable'">Local Variable:</div>
                        <div class="mt-[5px] " *ngIf="componentData.entity == 'global_variable'">Global Variable:</div>

      
                            <select *ngIf="componentData.entity == 'local_variable'" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc]" [(ngModel)]="componentData.entityVariable" (change)="dynamicVaraibleChanged(i)">
                                <ng-container *ngFor="let key of getKeys(uiSharedService.selectedPage.localVariable)">
                                    <option *ngIf="getType(uiSharedService.selectedPage.localVariable[key]) == componentData.allowedType " [ngValue]="key">
                                      {{ key }}
                                    </option>
                                    <option *ngIf="getType(uiSharedService.selectedPage.localVariable[key]) == 'STRING' && componentData.allowedType == 'ENUM' " [ngValue]="key">
                                        {{ key }}
                                      </option>
                                  </ng-container>
                            </select>
                            <select *ngIf="componentData.entity == 'global_variable'" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc]" [(ngModel)]="componentData.entityVariable" (change)="dynamicVaraibleChanged(i)">
                                <ng-container *ngFor="let key of getKeys(globalVariablesService.globalVariable)">
                                    <option *ngIf="getType(globalVariablesService.globalVariable[key]) == componentData.allowedType " [ngValue]="key">
                                      {{ key }}
                                    </option>
                                    <option *ngIf="getType(globalVariablesService.globalVariable[key]) == 'STRING' && componentData.allowedType == 'ENUM' " [ngValue]="key">
                                        {{ key }}
                                      </option>
                                  </ng-container>
                            </select>
                    
                        <div class="mt-[5px] " *ngIf="componentData.entity !== '' && componentData.entity !== 'local_variable' && componentData.entity !== 'global_variable'"> Variable:</div>
                        <ng-container *ngFor="let entity of uiSharedService.newEntityTransformedListForSelected" class="">
                        <select *ngIf="entity.id == componentData.entity" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc]" [(ngModel)]="componentData.entityVariable" (change)="dynamicVaraibleChanged()">
                            <ng-container *ngFor="let key of getKeys(entity.data)">
                                <option *ngIf="getType(entity.data[key]) == componentData.allowedType && key !== 'id' && key !== 'id_auto_increment'"  [ngValue]="key">
                                  {{ key }}
                                </option>

                                <option *ngIf="getType(entity.data[key]) == 'STRING' && componentData.allowedType == 'ENUM' && key !== 'id' && key !== 'id_auto_increment'"  [ngValue]="key">
                                    {{ key }}
                                  </option>
                              </ng-container>
                        </select>
                        </ng-container>
                        <ng-container *ngFor="let entity of uiSharedService.localEntityList" class="">
                            <select *ngIf="entity.id == componentData.entity" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc] w-[200px]" [(ngModel)]="componentData.entityVariable" (change)="dynamicVaraibleChanged()">
                               <ng-container *ngFor="let key of getKeys(entity.data)">
                                    <option *ngIf="getType(entity.data[key]) == componentData.allowedType && key !== 'id' && key !== 'id_auto_increment'"  [ngValue]="key">
                                      {{ key }}
                                    </option>

                                    <option *ngIf="getType(entity.data[key]) == 'STRING' && componentData.allowedType == 'ENUM' && key !== 'id' && key !== 'id_auto_increment'"  [ngValue]="key">
                                        {{ key }}
                                      </option>
                                  </ng-container>
                            </select>
                        </ng-container>
                        <ng-container *ngFor="let entity of pageService.globalEntityList" class="">
                            <select *ngIf="entity.id == componentData.entity" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc] w-[200px]" [(ngModel)]="componentData.entityVariable" (change)="dynamicVaraibleChanged()">
                               <ng-container *ngFor="let key of getKeys(entity.data)">
                                    <option *ngIf="getType(entity.data[key]) == componentData.allowedType && key !== 'id' && key !== 'id_auto_increment'"  [ngValue]="key">
                                      {{ key }}
                                    </option>
                                    <option *ngIf="getType(entity.data[key]) == 'STRING' && componentData.allowedType == 'ENUM' && key !== 'id' && key !== 'id_auto_increment'"  [ngValue]="key">
                                        {{ key }}
                                      </option>
                                  </ng-container>
                            </select>
                        </ng-container>
                    </div>

                    <div class=" self-center" *ngIf="componentData.type == 'dynamic' && componentData.allowedType == 'ARRAY_OF_OBJECTS'">
                        <div class=" border-b border-t border-solid border-yellow-500 rounded-md"> {{componentData.key}}</div>
                        <div class="mt-[5px] " > Entity:</div>
                        <select class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc] w-[200px]" [(ngModel)]="componentData.entity" (change)="dynamicEntityChanged(i)">
                            <option value="">Select Entity</option>
                            <option value="local_variable">LOCAL VARIABLE</option>
                        </select>

                        <div class="mt-[5px] " *ngIf="componentData.entity == 'local_variable'">Local Variable:</div>

      
                            <select *ngIf="componentData.entity == 'local_variable'" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc]" [(ngModel)]="componentData.entityVariable" (change)="dynamicVaraibleChanged(i)">
                                <ng-container *ngFor="let key of getKeys(uiSharedService.selectedPage.localVariable)">

                                    <option *ngIf="getType(uiSharedService.selectedPage.localVariable[key]) == 'STRING' && getType(uiSharedService.selectedPage.localVariable[key]) == 'NUMBER' && getType(uiSharedService.selectedPage.localVariable[key]) == 'BOOLEAN'" [ngValue]="key">
                                        {{ key }}
                                      </option>
                                  </ng-container>
                            </select>
                    </div>

                </ng-container>
                <ng-container *ngIf="componentData.objectType == 'output'">

                <div class="p-[5px] "> OUTPUT:</div>
                  <div class=" self-center" *ngIf="componentData.type == 'dynamic'">
                    <div class=" border-b border-t border-solid border-yellow-500 rounded-md"> {{componentData.key}}</div>
                        <div class="mt-[5px] " > Entity: </div>
                        <select class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc] w-[200px]" [(ngModel)]="componentData.entity" (change)="dynamicEntityChanged(i)">
                            <option value="">Select Entity</option>
                            <option value="local_variable">LOCAL VARIABLE</option>
                            <option value="global_variable">GLOBAL VARIABLE</option>
                            <option *ngFor="let entity of uiSharedService.newEntityTransformedListForSelected" [value]="entity.id"> {{ entity.name}}</option>
                            <option *ngFor="let entity of uiSharedService.localEntityList" [value]="entity.id"> {{ entity.name}}</option>
                            <option *ngFor="let entity of pageService.globalEntityList" [value]="entity.id"> {{ entity.name}}</option>
                        </select>

                        <div class="mt-[5px] " *ngIf="componentData.entity == 'local_variable'">Local Variable:</div>
                        <div class="mt-[5px] " *ngIf="componentData.entity == 'global_variable'">Local Variable:</div>

      
                            <select *ngIf="componentData.entity == 'local_variable'" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc]" [(ngModel)]="componentData.entityVariable" (change)="dynamicVaraibleChanged(i)">
                                <ng-container *ngFor="let key of getKeys(uiSharedService.selectedPage.localVariable)">
                                    <option *ngIf="getType(uiSharedService.selectedPage.localVariable[key]) == componentData.allowedType " [ngValue]="key">
                                      {{ key }}
                                    </option>
                                  </ng-container>
                            </select>

                            <select *ngIf="componentData.entity == 'global_variable'" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc]" [(ngModel)]="componentData.entityVariable" (change)="dynamicVaraibleChanged(i)">
                                <ng-container *ngFor="let key of getKeys(globalVariablesService.globalVariable)">
                                    <option *ngIf="getType(globalVariablesService.globalVariable[key]) == componentData.allowedType " [ngValue]="key">
                                      {{ key }}
                                    </option>
                                  </ng-container>
                            </select>
                    
                        <div class="mt-[5px] " *ngIf="componentData.entity !== '' && componentData.entity !== 'local_variable' && componentData.entity !== 'global_variable'"> Variable:</div>
                        <ng-container *ngFor="let entity of uiSharedService.newEntityTransformedListForSelected" class="">
                            <select *ngIf="entity.id == componentData.entity" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc]" [(ngModel)]="componentData.entityVariable" (change)="dynamicVaraibleChanged()">
                                <ng-container *ngFor="let key of getKeys(entity.data)">
                                    <option *ngIf="getType(entity.data[key]) == componentData.allowedType && key !== 'id' && key !== 'id_auto_increment'"  [ngValue]="key">
                                    {{ key }}
                                    </option>
                                </ng-container>
                            </select>
                        </ng-container>
                        <ng-container *ngFor="let entity of uiSharedService.localEntityList" class="">
                            <select *ngIf="entity.id == componentData.entity" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc] w-[200px]" [(ngModel)]="componentData.entityVariable" (change)="dynamicVaraibleChanged()">
                               <ng-container *ngFor="let key of getKeys(entity.data)">
                                    <option *ngIf="getType(entity.data[key]) == componentData.allowedType && key !== 'id' && key !== 'id_auto_increment'"  [ngValue]="key">
                                      {{ key }}
                                    </option>
                                </ng-container>
                            </select>
                        </ng-container>
                        <ng-container *ngFor="let entity of pageService.globalEntityList" class="">
                            <select *ngIf="entity.id == componentData.entity" class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc] w-[200px]" [(ngModel)]="componentData.entityVariable" (change)="dynamicVaraibleChanged()">
                               <ng-container *ngFor="let key of getKeys(entity.data)">
                                    <option *ngIf="getType(entity.data[key]) == componentData.allowedType && key !== 'id' && key !== 'id_auto_increment'"  [ngValue]="key">
                                      {{ key }}
                                    </option>
                                </ng-container>
                            </select>
                        </ng-container>
                        <div class="p-[5px] "> Functions:</div>
                        <div class=" self-center">
                              <select class="border border-solid border-[#cccccc] rounded-md bg-black text-[#cccccc]" [(ngModel)]="selectedFunctionName" (change)="dynamicFunctionChanged(i)">
                                  <option value="">Select Function</option>
                                  <option *ngFor="let function of uiSharedService.selectedPage.functions" [value]="function.name"> {{ function.name}}</option>
                              </select>
                          </div>
                          <div class="p-[5px] "> 
                            <div class="" *ngFor="let function of componentData.functions"> {{ function }}</div>
                          </div>
                    </div>
                </ng-container>
                    </div>
                <div class="items-center mt-[10px]">
                    <div class="">
                       <div class=" mb-[10px]"> Shift Position: </div>
                       <div class="flex justify-center gap-[10px]" *ngIf="selectedComponentIndexInParent !== -1">
                        <div *ngIf="selectedComponentIndexInParent !== 0" class="p-[5px] rounded-md border border-solid border-[#cccccc] cursor-pointer hover:bg-black" (click)="changeComponentUpByOne()">
                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C12.2652 3 12.5196 3.10536 12.7071 3.29289L19.7071 10.2929C20.0976 10.6834 20.0976 11.3166 19.7071 11.7071C19.3166 12.0976 18.6834 12.0976 18.2929 11.7071L13 6.41421V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V6.41421L5.70711 11.7071C5.31658 12.0976 4.68342 12.0976 4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929L11.2929 3.29289C11.4804 3.10536 11.7348 3 12 3Z" fill="#cccccc"/>
                                </svg>     </div>
                              <div *ngIf="selectedComponentIndexInParent !== (selectedSection.components.length -1)" class="p-[5px] rounded-md border border-solid border-[#cccccc] cursor-pointer hover:bg-black" (click)="changeComponentDownByOne()">
                                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C12.5523 3 13 3.44772 13 4V17.5858L18.2929 12.2929C18.6834 11.9024 19.3166 11.9024 19.7071 12.2929C20.0976 12.6834 20.0976 13.3166 19.7071 13.7071L12.7071 20.7071C12.3166 21.0976 11.6834 21.0976 11.2929 20.7071L4.29289 13.7071C3.90237 13.3166 3.90237 12.6834 4.29289 12.2929C4.68342 11.9024 5.31658 11.9024 5.70711 12.2929L11 17.5858V4C11 3.44772 11.4477 3 12 3Z" fill="#cccccc"/>
                                    </svg>
                              </div>
                       </div>
                    </div>
                    <div class="text-red-500 text-center border border-solid border-red-500 rounded-md cursor-pointer mt-[20px] mb-[20px]" (click)="deleteSelectedComponent()"> Delete </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
