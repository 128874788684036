import { Routes } from '@angular/router';
import { UiEditorComponent } from './dashboard/ui-editor/ui-editor.component';
import { LoginComponent } from './auth/login/login.component';
import { authGuard } from './services/auth.guard';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';

export const routes: Routes = [
  { path: 'ui-editor', component: UiEditorComponent },
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent,
  },
  
];
