import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Auth, confirmPasswordReset } from '@angular/fire/auth';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { verifyPasswordResetCode } from 'firebase/auth';
import { take } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent {
  isSuccessfull = false;
  details?: ResetPasswordData;
  password?: string;
  confirmNewPassword?: string;
  email?: string;
  private auth: Auth = inject(Auth);

  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.queryParams.pipe(take(1)).subscribe(async (params) => {
      this.details = { ...params } as ResetPasswordData;
      this.email = await verifyPasswordResetCode(
        this.auth,
        this.details?.oobCode || ''
      );
    });
  }
  async resetPassword() {
    if (this.confirmNewPassword !== this.password) {
      alert('Passwords do not match');
      return;
    }
    try {
      await confirmPasswordReset(
        this.auth,
        this.details?.oobCode || '',
        this.password || ''
      );
      this.isSuccessfull = true;
    } catch (error) {}
  }

  navigateHome() {
    this.router.navigate(['/']);
  }
}

interface ResetPasswordData {
  mode: string;
  oobCode: string;
  apiKey: string;
}
