import { Injectable } from '@angular/core';
import { BaseRepositoryService } from './base.service';
import { Page } from '../model/page.model';
import { AuthService } from './auth.service';
import { Entity, TypeData } from '../model/entity.model';

@Injectable({
  providedIn: 'root',
})
export class PageService extends BaseRepositoryService<Page> {
  override collectionPath: string = '/pages';
  public firstCall: boolean = true;
  selectedPage: Page = {
    name: 'Strange',
    id: 'Strange',
    sections: [],
    entites: [],
    localVariable: {
      defaultFlag: false,
      defaultString: '',
      defaultNumber: 0,
    },
    functions: [],
  };
  newEntityListForSelected: Entity[] = [];
  newEntityTransformedListForSelected: any[] = [];
  localEntityList: any[] = [];
  globalEntityList: any[] = [];
  constructor(private authService: AuthService) {
    super();
    this.authService.isAuthenticated().subscribe((isAuth) => {
      if (isAuth) {
        this.collectionPath =
          this.authService.baseCollectionPath + this.collectionPath;
      }
    });
  }

  async createEntityData(typeData: TypeData[]) {
    var data: any = {};
    var dataType: any = {};
    for (let i = 0; i < typeData.length; i++) {
      if (
        typeData[i].name !== 'id' &&
        typeData[i].name !== 'id_auto_increment' &&
        typeData[i].name !== 'parent_id'
      ) {
        if (
          typeData[i].type == 'UUID' &&
          typeData[i].type == 'AUTO_INCREMENT'
        ) {
        } else {
          dataType[typeData[i].name] = typeData[i].type;
        }
        if (typeData[i].type == 'STRING') {
          data[typeData[i].name] = '';
        } else if (typeData[i].type == 'NUMBER') {
          data[typeData[i].name] = 0;
        } else if (typeData[i].type == 'BOOLEAN') {
          data[typeData[i].name] = true;
        } else if (typeData[i].type == 'ARRAY_OF_STRING') {
          data[typeData[i].name] = [''];
        } else if (typeData[i].type == 'ARRAY_OF_NUMBER') {
          data[typeData[i].name] = [0];
        } else if (typeData[i].type == 'ARRAY_OF_OBJECT') {
          data[typeData[i].name] = [
            await this.createEntityData(typeData[i].data).then(
              (returnedData: any) => {
                return returnedData;
              }
            ),
          ];
        } else if (typeData[i].type == 'UUID') {
          // data[typeData[i].name] = '';
          // do NTG as auto increment has to be auto generated
        } else if (typeData[i].type == 'AUTO_INCREMENT') {
          // data[typeData[i].name] = -1;
          // do NTG as auto increment has to be auto generated
        } else if (typeData[i].type == 'ENTITY_REFERENCE') {
          data[typeData[i].name] = '';
        }
      }
    }
    return data;
  }
}
