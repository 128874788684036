import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { take } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  constructor(private router: Router) {
    this.router.events.pipe(take(1)).subscribe((event: any) => {
      if (event['url'] && event['url'].includes('resetPassword')) {
      } else {
        this.router.navigate(['']);
      }
    });
  }
  title = 'olympus-runtime';
}
