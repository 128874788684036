<div class="h-full relative">
  <div class="flex justify-between">
    <div class="flex ml-[100px] mt-[32px]">
      <div class="">
        <img src="assets/logo.png" alt="Pegasus" />
      </div>
      <div class="ml-[4px] text-[18px] font-medium leading-[21.6px]">
        <div class="">Tradais</div>
        <div class="">DMS</div>
      </div>
    </div>
  </div>
  <div class="w-full h-full flex justify-center items-center absolute top-0">
    <div *ngIf="!isSuccessfull" class="flex flex-col items-center w-1/3 gap-6">
      <h1 class="text-3xl font-medium">Change Password</h1>
      <p class="text-[#777] text-base">
        Reset password for this email -
        <span class="font-medium">{{ email }}</span
        >. Enter it below to reset your password.
      </p>
      <div class="w-full">
        <label class="text-[#777] text-base font-normal" for="new-password"
          >New password<span class="text-red-600">*</span></label
        >
        <input
          type="password"
          required
          [(ngModel)]="password"
          id="new-password"
          class="w-full focus:outline-none rounded-xl border text-base p-2"
        />
      </div>
      <div class="w-full">
        <label
          class="text-[#777] text-base font-normal"
          for="confirm-new-password"
          >Confirm new password<span class="text-red-600">*</span></label
        >
        <input
          [(ngModel)]="confirmNewPassword"
          type="password"
          required
          id="confirm-new-password"
          class="w-full focus:outline-none rounded-xl border text-base p-2"
        />
      </div>
      <button
        (click)="resetPassword()"
        type="submit"
        class="bg-[#216AD3] text-white px-5 py-3 rounded-[40px] w-fit"
      >
        Reset password
      </button>
    </div>
    <div *ngIf="isSuccessfull" class="flex flex-col items-center w-1/3 gap-6">
      <h1 class="text-3xl font-medium">Password Changed</h1>
      <p>Your password has been change successfully.</p>
      <button
        (click)="navigateHome()"
        type="submit"
        class="bg-[#216AD3] text-white px-5 py-3 rounded-[40px] w-fit"
      >
        Back to login
      </button>
    </div>
  </div>
</div>
