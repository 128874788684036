import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ClientService } from '../../services/client.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './login.component.html',
})
export class LoginComponent {
  public email = '';
  public password = '';
  constructor(private authService: AuthService) {
    this.authService.isAuthenticated().subscribe((value) => {});
  }
  public async login() {
    await this.authService.signInWithEmail(this.email, this.password);
  }
}
