"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var DefaultAppSyncMutationAdapter_1 = require("./DefaultAppSyncMutationAdapter");
var DefaultAppSyncQueryAdapter_1 = require("./DefaultAppSyncQueryAdapter");
exports.default = {
  DefaultAppSyncQueryAdapter: DefaultAppSyncQueryAdapter_1.default,
  DefaultAppSyncMutationAdapter: DefaultAppSyncMutationAdapter_1.default
};
