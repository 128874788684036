import { Injectable } from '@angular/core';
import { Page } from '../../model/page.model';

@Injectable({
  providedIn: 'root'
})
export class UiSharedServiceService {
  selectedPage: Page = {
    name: 'Strange',
    id: 'Strange',
    sections: [],
    entites: [],
    localVariable: {
      defaultFlag: false,
      defaultString: '',
      defaultNumber: 0,
    },
    functions: [],
  };
  newEntityTransformedListForSelected: any[] =[];
  localEntityList: any[] = [];
  constructor() { }
}
