import { Injectable } from '@angular/core';
import { BaseRepositoryService } from './base.service';
import { ClientData } from '../model/client.model';

@Injectable({
  providedIn: 'root',
})
export class ClientService extends BaseRepositoryService<ClientData> {
  override collectionPath: string = 'client';
  public selectedClient: ClientData = {
    name: '',
    id: '',
    roles: [],
  };
  public selectedClientId: string = '';
  constructor() {
    super();
  }
}
