import { Injectable } from '@angular/core';
import { BaseRepositoryService } from './base.service';
import { Entity } from '../model/entity.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class EntityService extends BaseRepositoryService<Entity> {
  override collectionPath: string = '/entity';
  completeFinalizedEntityList : string[] =[]; // this includes the sub entities like arrayof objects 
// newEntityTypeList: any[]=[];
  constructor(private authService: AuthService) { 
    super();
    this.collectionPath = this.authService.baseCollectionPath + this.collectionPath;
    this.query(1000);
  }

}
