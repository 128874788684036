import { inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import {
  Firestore,
  QueryConstraint,
  Transaction,
  collection,
  deleteDoc,
  doc,
  endBefore,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
  startAfter,
  updateDoc,
} from '@angular/fire/firestore';
import { Storage, ref, uploadBytes } from '@angular/fire/storage';
import { ReplaySubject, first } from 'rxjs';
import { Auditable, Base, HasAttachments } from '../model/base.model';

export abstract class BaseRepositoryService<T extends Base> {
  private readonly _firestore = inject(Firestore);
  private readonly _auth = inject(Auth);
  // private readonly _storage = inject(Storage);
  abstract readonly collectionPath: string;
  private rawValues: T[] = [];
  public $values = new ReplaySubject<T[]>(1);

  private queries: QueryConstraint[] = [];
  private pageSize: number = 10;

  async queryById(id: string): Promise<T> {
    console.log('queryById', `${this.collectionPath}/${id}`);
    const docRef = doc(this._firestore, `${this.collectionPath}/${id}`);
    const docSnap = await getDoc(docRef);
    return docSnap.data() as T;
  }

  async query(
    pageSize: number = 15,
    flag: boolean = true,
    ...queries: QueryConstraint[]
  ) {
    this.queries = queries;
    this.pageSize = pageSize;
    try {
      await getDocs(
        query(
          collection(this._firestore, this.collectionPath),
          ...this.queries,
          limit(this.pageSize)
        )
      )
        .then((snap) => snap.docs.map((doc) => doc.data()))
        .then((data) => {
          this.rawValues = data as T[];
          if (flag) {
            this.$values.next(data as T[]);
          }
        });
      return this.rawValues;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  // async appendNextPage() {
  //   getDocs(
  //     query(
  //       collection(this._firestore, this.collectionPath),
  //       ...this.queries,
  //       orderBy('id', 'desc'),
  //       startAfter(this.rawValues[this.rawValues.length - 1].id),
  //       limit(this.pageSize)
  //     )
  //   )
  //     .then((snap) => snap.docs.map((doc) => doc.data()))
  //     .then((data) => {
  //       this.rawValues = [...this.rawValues, ...(data as T[])];
  //       this.$values.next([...this.rawValues]);
  //     });
  // }

  // async nextPage() {
  //   getDocs(
  //     query(
  //       collection(this._firestore, this.collectionPath),
  //       ...this.queries,
  //       orderBy('id', 'desc'),
  //       startAfter(this.rawValues[this.rawValues.length - 1].id),
  //       limit(this.pageSize)
  //     )
  //   )
  //     .then((snap) => snap.docs.map((doc) => doc.data()))
  //     .then((data) => {
  //       this.rawValues = data as T[];
  //       this.$values.next(data as T[]);
  //     });
  // }
  // async prevPage() {
  //   getDocs(
  //     query(
  //       collection(this._firestore, this.collectionPath),
  //       ...this.queries,
  //       orderBy('id', 'desc'),
  //       endBefore(this.rawValues[0].id),
  //       limit(this.pageSize)
  //     )
  //   )
  //     .then((snap) => snap.docs.map((doc) => doc.data()))
  //     .then((data) => {
  //       this.rawValues = data as T[];
  //       this.$values.next(data as T[]);
  //     });
  // }

  // async upsert(entity: T | Omit<T, 'id' | keyof Auditable>) {
  //   if ((entity as any).id) {
  //     this.update(entity as T);
  //   } else {
  //     this.create(entity);
  //   }
  // }

  async update(entity: T) {
    // await this.saveAttachments(entity, entity.id.toString());
    await updateDoc(
      doc(this._firestore, this.collectionPath, entity.id.toString()),
      entity as any
      // {
      //   ...(entity as any),
      //   updatedAt: serverTimestamp(),
      //   updatedBy: this._auth.currentUser?.email,
      // }
    );
    // this.$values.pipe(first()).subscribe((currentArray) => {
    //   const index = currentArray.findIndex((item) => item.id === entity.id);
    //   if (index !== -1) {
    //     currentArray[index] = entity;
    //     this.$values.next(currentArray);
    //   } else {
    //     console.log('error on update funciton');
    //   }
    // });
  }

  async create(entity: T): Promise<string> {
    var newId: string = '';
    await runTransaction(this._firestore, async (transaction) => {
      // const count = await this.generateNewID(transaction);
      // const id = count.toString();
      // newId = id;
      // await this.saveAttachments(entity, id);
      // const docToBeSaved = {
      //   ...entity,
      // createdAt: serverTimestamp(),
      // updatedAt: serverTimestamp(),
      // createdBy: this._auth.currentUser?.email,
      // updatedBy: this._auth.currentUser?.email,
      // };
      transaction.set(
        doc(this._firestore, this.collectionPath, entity.id),
        entity
      );
      // transaction.set(doc(this._firestore, 'metadata', this.collectionPath), {
      //   count,
      // });
    });
    //! has to be optimized and changed
    this.query(1000).then((value) => {
      this.$values.pipe(first()).subscribe((currentArray) => {
        // currentArray.unshift(value[0]);
        this.$values.next(currentArray);
      });
    });
    return newId;
  }

  // async remove(id: Entity['id']) {
  //   await deleteDoc(doc(this._firestore, this.collectionPath, id.toString()));
  //   this.rawValues = this.rawValues.filter((ele) => ele.id !== id);
  //   this.$values.next([...this.rawValues]);
  // }

  // private async saveAttachments(entity: HasAttachments, id: string) {
  //   const { attachments } = entity;
  //   if (!attachments) return;
  //   for (let name in attachments) {
  //     const { file } = attachments[name];
  //     if (!file) {
  //       continue;
  //     }
  //     const attachRef = ref(
  //       this._storage,
  //       `${this.collectionPath}/${id}/${name}. ${file.name.split('.').pop()}`
  //     );

  //     const result = await uploadBytes(attachRef, file);
  //     const attachment = attachments[name];
  //     attachment.filePath = result.metadata.fullPath;
  //     attachment.fileName = result.metadata.name;
  //     delete attachment.file;
  //   }
  // }

  // private async generateNewID(transaction: Transaction) {
  //   const metadata = await transaction
  //     .get(doc(this._firestore, 'metadata', this.collectionPath))
  //     .then((snap) => snap.data());
  //   if (!metadata) {
  //     return 1;
  //   }
  //   return +metadata['count'] + 1;
  // }
}
