import { CommonModule } from '@angular/common';
import {
  Component,
  ComponentRef,
  ElementRef,
  HostListener,
  QueryList,
  Type,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PageService } from '../../services/page.service';
import { Page, Sections, Components } from '../../model/page.model';
import { Router } from '@angular/router';
import { ComponentsComponent } from './components/components.component';
import { EntityService } from '../../services/entity.service';
import { Entity, TypeData } from '../../model/entity.model';

import { Subscription, firstValueFrom, take, throwError } from 'rxjs';
import { CrudService } from '../../services/crud.service';
import { GlobalVariablesService } from '../../services/global-variables.service';
import { UiSharedServiceService } from './ui-shared-service.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-ui-editor',
  standalone: true,
  imports: [FormsModule, CommonModule, ComponentsComponent],
  templateUrl: './ui-editor.component.html',
})
export class UiEditorComponent {
  data: any = {};
  page: Page = {
    name: 'Home',
    id: 'Home',
    sections: [
      {
        name: 'Head',
        type: 'section',
        id: 'head',
        styles: 'flex justify-between flex-wrap flex-grow',
        hidden: {
          type: 'static',
          entity: '',
          entityVariable: '',
          allowedType: 'BOOLEAN',
          value: false,
        },
        components: [],
      },
    ],
    entites: [],
    localVariable: {
      defaultFlag: false,
      defaultString: '',
      defaultNumber: 0,
    },
    functions: [],
  };
  selectedSectionIndex: number[] = [-1, -1];
  selectedComponentIndex: number = -1;
  leftNavReloadFlag = true;
  componentReloadFlag = true;
  count = 0;
  constructor(
    public pageService: PageService,
    private router: Router,
    private crudService: CrudService,
    private entityService: EntityService,
    public globalVariablesService: GlobalVariablesService,
    public uiSharedService: UiSharedServiceService,
    public authService: AuthService
  ) {
    this.authService.isAuthenticated().subscribe(async (isAuth) => {
      console.log('count', this.count++);
      if (isAuth && this.pageService.firstCall) {
        await this.pageService.query(1000);
        console.log(this.pageService.collectionPath);
        this.pageService.$values.subscribe((ele) => {
          const def = ele.find((page) => page.id === 'defaultPage');
          this.pageService.selectedPage = def as Page;
          this.initialSetupForPage();
          this.pageService.firstCall = false;
        });
      } else {
        !this.pageService.firstCall &&  this.initialSetupForPage()  
      }
    });
  }

  entityList: any[] = [];
  localEntityList: any[] = [];

  // initializing the page
  async initialSetupForPage() {
    try {
      this.uiSharedService.selectedPage = structuredClone(
        this.pageService.selectedPage
      );
      this.uiSharedService.newEntityTransformedListForSelected =
        structuredClone(this.pageService.newEntityTransformedListForSelected);
      this.uiSharedService.localEntityList = structuredClone(
        this.pageService.localEntityList
      );
      //  this.uiSharedService.selectedPage = this.uiSharedService.selectedPage;
      //  this.uiSharedService.localEntityList = this.uiSharedService.localEntityList;
      //  this.uiSharedService.newEntityTransformedListForSelected = this.uiSharedService.newEntityTransformedListForSelected
      // this.uiSharedService.localEntityList =[];

      this.runInternalFunction('defaultFunction');
      setTimeout(() => {
        this.leftNavReloadFlag = !this.leftNavReloadFlag;
        this.componentReloadFlag = !this.componentReloadFlag;
      }, 100);
      setTimeout(() => {
        this.createComponentBasedOnConfig();
      }, 200);

      return true;
    } catch (error) {
      console.error('Error fetching data:', error);
      return false;
    }
  }

  // end  of initializing
  ngOnInit() {
    // ! setTimeOut has to be rmeoved and another approuch has to be implemented
    this.leftNavReloadFlag = !this.leftNavReloadFlag;
    this.componentReloadFlag = !this.componentReloadFlag;
  }

  // add section
  sectionAddFlag = false;
  addSection() {
    this.sectionAddFlag = !this.sectionAddFlag;
  }
  newSectionName = '';
  addSectionToPage() {
    this.uiSharedService.selectedPage.sections.push({
      name: JSON.parse(JSON.stringify(this.newSectionName)),
      id: JSON.parse(JSON.stringify(this.newSectionName)),
      type: 'section',
      styles: 'flex justify-between flex-wrap flex-grow',
      hidden: {
        type: 'static',
        entity: '',
        entityVariable: '',
        allowedType: 'BOOLEAN',
        value: false,
      },
      components: [],
    });
    this.newSectionName = '';
    this.sectionAddFlag = !this.sectionAddFlag;
  }
  // add components to sections
  leftNavComponantsShowFlag = false;
  selectedSectionIndexForComponent = [-1, -1];
  leftNavComponantsShowFlagChange(data: any) {
    const event: Event = data.event;
    const index: number = data.i;
    event.stopPropagation(); // Prevent the click event from bubbling up
    this.leftNavComponantsShowFlag = true;
    this.selectedSectionIndexForComponent = [index, -1];
  }
  // to close the bar on click else where on the page
  @ViewChild('leftNav') leftNav!: ElementRef;
  @ViewChild('leftNavContent') leftNavContent!: ElementRef;

  // end to add section

  // add sub-section to selected section
  newSubSectionName = '';
  addSubSectionToSection() {
    if (this.selectedSectionIndex[1] == -1) {
      this.uiSharedService.selectedPage.sections[
        this.selectedSectionIndex[0]
      ].components.push({
        name: this.newSubSectionName,
        id: this.newSubSectionName,
        type: 'section',
        styles: 'flex justify-between flex-wrap flex-grow',
        hidden: {
          type: 'static',
          entity: '',
          entityVariable: '',
          allowedType: 'BOOLEAN',
          value: false,
        },
        components: [],
        componentDataBinding: [],
      });
    } else {
      this.recursiveNumberBySectionIndexForSection(
        0,
        this.uiSharedService.selectedPage.sections[this.selectedSectionIndex[0]]
      );
    }
    this.selectedComponentIndex = -1;

    this.leftNavReloadFlag = !this.leftNavReloadFlag;
    this.componentReloadFlag = !this.componentReloadFlag;
    setTimeout(() => {
      this.leftNavReloadFlag = !this.leftNavReloadFlag;
      this.componentReloadFlag = !this.componentReloadFlag;
    }, 100);
    setTimeout(() => {
      this.createComponentBasedOnConfig();
    }, 200);
    this.newSubSectionName = '';
  }
  // adding sub-section to the above section recersively
  recursiveNumberBySectionIndexForSection(
    incrementIndex: number,
    section: Sections | Components
  ) {
    if (incrementIndex == this.selectedSectionIndex[1]) {
      section.components.push({
        name: this.newSubSectionName,
        id: this.newSubSectionName,
        type: 'section',
        styles: 'flex justify-between flex-wrap flex-grow',
        hidden: {
          type: 'static',
          entity: '',
          entityVariable: '',
          allowedType: 'BOOLEAN',
          value: false,
        },
        components: [],
        componentDataBinding: [],
      });
      const returnData = {
        incrementIndexChange: incrementIndex,
        flag: true,
        section: section,
      };
      return returnData;
    }
    for (let i = 0; i < section.components.length; i++) {
      if (section.components[i].type == 'section') {
        incrementIndex++;

        var returnedData = this.recursiveNumberBySectionIndexForSection(
          incrementIndex,
          section.components[i]
        );
        incrementIndex = returnedData.incrementIndexChange;
        if (returnedData.flag) {
          const returnData = {
            incrementIndexChange: incrementIndex,
            flag: true,
            section: section,
          };
          return returnData;
        }
      }
    }
    const returnData = {
      incrementIndexChange: incrementIndex,
      flag: false,
      section: section,
    };
    return returnData;
  }
  // end of adding sub section
  // add component to selected section
  addComponentToSection(index: number) {
    // TODO: finalize the componentDataBinding and create entry in the data decided to go with what ever is required for mutations and table data has to be seperately maintained
    //     var componentDataBinding = {};
    //     for(const key in this.componentConfig[index].inputs) {
    // componentDataBinding
    //     }
    if (this.selectedSectionIndex[1] == -1) {
      this.uiSharedService.selectedPage.sections[
        this.selectedSectionIndex[0]
      ].components.push({
        name: this.componentConfig[index].id,
        id: this.componentConfig[index].id,
        type: 'component',
        styles: '',
        hidden: {
          type: 'static',
          entity: '',
          entityVariable: '',
          allowedType: 'BOOLEAN',
          value: false,
        },
        components: [],
        componentDataBinding: [],
      });
      this.assignValueToComponent(
        this.uiSharedService.selectedPage.sections[this.selectedSectionIndex[0]]
          .components[
          this.uiSharedService.selectedPage.sections[
            this.selectedSectionIndex[0]
          ].components.length - 1
        ]
      );
    } else {
      this.recursiveNumberBySectionIndexForComponent(
        0,
        this.uiSharedService.selectedPage.sections[
          this.selectedSectionIndex[0]
        ],
        index
      );
    }

    this.selectedSectionIndexForComponent = [-1, -1];
    this.selectedComponentIndex = -1;
    this.leftNavComponantsShowFlag = false;
    // ! setTimeOut has to be rmeoved and another approuch has to be implemented
    this.leftNavReloadFlag = !this.leftNavReloadFlag;
    this.componentReloadFlag = !this.componentReloadFlag;
    setTimeout(() => {
      this.leftNavReloadFlag = !this.leftNavReloadFlag;
      this.componentReloadFlag = !this.componentReloadFlag;
    }, 100);
    setTimeout(() => {
      this.createComponentBasedOnConfig();
    }, 200);
  }
  // add component to the selcted section reecursively
  recursiveNumberBySectionIndexForComponent(
    incrementIndex: number,
    section: Sections | Components,
    selectedComponentIndex: number
  ) {
    if (incrementIndex == this.selectedSectionIndex[1]) {
      section.components.push({
        name: this.componentConfig[selectedComponentIndex].id,
        id: this.componentConfig[selectedComponentIndex].id,
        type: 'component',
        styles: '',
        hidden: {
          type: 'static',
          entity: '',
          entityVariable: '',
          allowedType: 'BOOLEAN',
          value: false,
        },
        components: [],
        componentDataBinding: [],
      });
      this.assignValueToComponent(
        section.components[section.components.length - 1]
      );
      const returnData = { incrementIndexChange: incrementIndex, flag: true };
      return returnData;
    }
    for (let i = 0; i < section.components.length; i++) {
      if (section.components[i].type == 'section') {
        incrementIndex++;

        var returnedData = this.recursiveNumberBySectionIndexForComponent(
          incrementIndex,
          section.components[i],
          selectedComponentIndex
        );

        incrementIndex = returnedData.incrementIndexChange;
        if (returnedData.flag) {
          const returnData = {
            incrementIndexChange: incrementIndex,
            flag: true,
          };
          return returnData;
        }
      }
    }
    const returnData = { incrementIndexChange: incrementIndex, flag: false };
    return returnData;
  }
  // assigin value to component
  assignValueToComponent(component: Components) {
    for (let i = 0; i < this.componentConfig.length; i++) {
      if (component.id == this.componentConfig[i].id) {
        for (let key of Object.keys(this.componentConfig[i].inputs)) {
          var value: any = '';
          if (this.componentConfig[i].inputs[key].type == 'STRING') {
            value = '';
          } else if (this.componentConfig[i].inputs[key].type == 'BOOLEAN') {
            value = false;
          }
          component.componentDataBinding.push({
            type: 'static',
            key: key,
            entity: '',
            entityVariable: '',
            allowedType: this.componentConfig[i].inputs[key].type,
            value: value,
            valueKey: '',
            objectType: 'input',
            // allowedData: '',
            allowedData: this.componentConfig[i].inputs[key].data,
            functions: [],
          });
        }
        for (let key of Object.keys(this.componentConfig[i].outputs)) {
          component.componentDataBinding.push({
            type: 'dynamic',
            key: key,
            entity: '',
            entityVariable: '',
            allowedType: this.componentConfig[i].outputs[key].type,
            value: '',
            valueKey: '',
            objectType: 'output',
            // allowedData: '',
            allowedData: this.componentConfig[i].outputs[key].data,
            functions: [],
          });
        }
      }
    }
  }

  // end of adding component
  // component seelction in a section
  // ! has to be removed just kept for safety : please remove if everything works fine
  // selectComponent(componentIndex: number) {
  //   this.selectedComponentIndex = componentIndex;
  // }
  // ! has to be removed just kept for safety : please remove if everything works fine

  // handeling import of other components
  // component config
  sampleData = 'hi';
  componentConfig: any = [{
    name: 'Head Lable',
    id: 'head-lable',
    component: () => import('../../common-components/dynamic-components/head-lable/head-lable.component')
    .then(it => it.HeadLableComponent),
    inputs: { name:  {type: 'STRING', data:{} } , placeHolder:  {type: 'STRING', data:{} }, fontColor:  {type: 'STRING', data:{} }, fontSize:  {type: 'NUMBER', data:{} }, fontWeight:  {type: 'NUMBER', data:{} } },
    outputs: []
    },{
      name: 'Text Input With Lable',
      id: 'text-input-with-lable',
      component: () => import('../../common-components/dynamic-components/text-input-with-lable/text-input-with-lable.component')
      .then(it => it.TextInputWithLableComponent),
      inputs: { lable: {type: 'STRING', data:{} },
    data: {type: 'STRING', data:{} },
    width: {type: 'NUMBER', data:{} },
    placeHolder: {type: 'STRING', data:{} },
    asteriskFlag: {type: 'BOOLEAN', data:{} },
    autoFillFlag: {type: 'BOOLEAN', data:{} },
    disabledFlag: {type: 'BOOLEAN', data:{} },
    validationData: {type: 'STRING', data:{} },
    validationFlag: {type: 'BOOLEAN', data:{} },
    inputType: {type: 'ENUM', data:['text', 'number', 'password'] } ,
    actionIcon: {type: 'ENUM', data:['next-arrow', 'closed-eye','+91'] } ,
    actionButtonFlag: {type: 'BOOLEAN', data:{} } ,
    actionButtonColor: {type: 'STRING', data:{} }},
    outputs: {dataChange: {type: 'STRING', data:{} }, actionButtonChanged: {type: 'BOOLEAN', data:{} }}
      },
      {
        name: 'Icon',
        id: 'icon',
        component: () => import('../../common-components/dynamic-components/icon/icon.component')
        .then(it => it.IconComponent),
        inputs: {type: {type: 'ENUM', data:['pegasus','need-help', 'copy-right', 'tradais-digital'] },color: {type: 'STRING', data:{} },width: {type: 'NUMBER', data:{} },height: {type: 'NUMBER', data:{} }},
        outputs: {},
        },
      {
        name: 'Toggle Button',
        id: 'toggle-button',
        component: () => import('../../common-components/dynamic-components/toggle-button/toggle-button.component')
        .then(it => it.ToggleButtonComponent),
        inputs: {flag: {type: 'BOOLEAN', data:{} }},
        outputs: {flagChange: {type: 'BOOLEAN', data:{} }},
        },
        {
          name: 'Main Button',
          id: 'main-button',
          component: () => import('../../common-components/dynamic-components/main-button/main-button.component')
          .then(it => it.MainButtonComponent),
          inputs: {displayData: {type: 'STRING', data:{} },
          width: {type: 'NUMBER', data:{} }, height: {type: 'NUMBER', data:{} }, paddingRight:{type:'NUMBER', data:{}},backgroundColor: {type: 'STRING', data:{} }, textColor: {type: 'STRING', data:{} }, borderColor: {type: 'STRING', data:{} },svg: {type: 'ENUM', data:['none', 'plus-square', 'filter', 'eye','back-arrow','home', 'draft', 'library'] }, disabledFlag: {type: 'BOOLEAN', data:{} },},
          outputs: {flagChange: {type: 'BOOLEAN', data:{} }},
          },
          {
            name: 'Drop Down',
            id: 'drop-down',
            component: () => import('../../common-components/dynamic-components/drop-down/drop-down.component')
            .then(it => it.DropDownComponent),
            inputs: {dropData: {type: 'ARRAY_OF_ENTITES', data:[] , dataTypes:['main-lable', 'sub-lable']}},
            outputs: {outputData: {type:'ENTITY', data:{}}, clickedFirstIndex: {type: 'NUMBER', data:{} }, clickedSecondIndex: {type: 'NUMBER', data:{} }},
            }];
  componentRefs: ComponentRef<any>[] = []; //? to be used in main page for data-binding these are examples for testing has to be refined
  @ViewChildren(ComponentsComponent)
  childComponents: QueryList<ComponentsComponent> | undefined;

  containers: ViewContainerRef[][] = [];
  container: ViewContainerRef[] = [];
  componentRefsData: Components[] = [];
  dataChangeSubscription: Subscription[][] = [];
  // function to add components
  async createComponentBasedOnConfig() {
    // this.container.clear();
    this.componentRefs = []; //? to be used in main page for data-binding
    this.componentRefsData = [];
    this.dataChangeSubscription = [];

    await this.childComponents
      ?.toArray()
      .forEach((childComponent: ComponentsComponent) => {
        childComponent.addContainerRefs();
      });

    // var container = this.container.toArray(); //! needs to be removed used before components-component was not generated
    this.container = [];
    for (let i = 0; i < this.containers.length; i++) {
      this.container = [...this.container, ...this.containers[i]];
    }
    // var container = this.container;
    // this.container.for
    var indexing: number = 0;
    for (
      let i = 0;
      i < this.uiSharedService.selectedPage.sections.length;
      i++
    ) {
      indexing = await this.recersiveForComponentsInNestedSections(
        this.uiSharedService.selectedPage.sections[i],
        indexing
      );
    }
    // this.componentConfig.forEach( async (componentConfig, index) => {
    //! not to remove untill the app is completed -  this is for reference
    //!  const componentInstance :any =  await componentConfig.component();
    //  this.container.forEach((vcr: ViewContainerRef, index: number) => {
    //  const componentRef1: any = vcr.createComponent(componentInstance);
    //  });
    // ! const componentRef1: any = container[index].createComponent(componentInstance);

    // });
    setTimeout(() => {
      this.selectedSectionIndexChanged(0);
    }, 100);
  }
  // for adding components recursively
  async recersiveForComponentsInNestedSections(
    sections: Sections | Components,
    indexing: number
  ) {
    for (let j = 0; j < sections.components.length; j++) {
      if (sections.components[j].type == 'component') {
        const desiredConfig = this.componentConfig.find(
          (config: any) => config.id === sections.components[j].id
        );
        if (desiredConfig) {
          // if(this.container[indexing])
          this.container[indexing].clear();
          const componentInstance: any = await desiredConfig.component();
          const componentRef: any =
            this.container[indexing].createComponent(componentInstance);
          //? to be used in main page for data-binding
          //  Object.entries(desiredConfig.inputs).forEach(([key,value]) => {
          //   value = sections.name
          //   componentRef.setInput(key, value);
          //  });
          var subscriptionRef: Subscription[] = [];
          for (
            let z = 0;
            z < sections.components[j].componentDataBinding.length;
            z++
          ) {
            if (
              sections.components[j].componentDataBinding[z].objectType ==
              'input'
            ) {
              if (
                sections.components[j].componentDataBinding[z].type == 'static'
              ) {
                componentRef.setInput(
                  sections.components[j].componentDataBinding[z].key,
                  sections.components[j].componentDataBinding[z].value
                );
              } else if (
                sections.components[j].componentDataBinding[z].type == 'dynamic'
              ) {
                if (
                  sections.components[j].componentDataBinding[
                    z
                  ].entity.includes('global_entity$')
                ) {
                  componentRef.setInput(
                    sections.components[j].componentDataBinding[z].key,
                    this.globalVariablesService.globalVariable[
                      sections.components[j].componentDataBinding[
                        z
                      ].entity.replace('global_entity$', '')
                    ].data[
                      sections.components[j].componentDataBinding[z]
                        .entityVariable
                    ]
                  );
                } else if (
                  sections.components[j].componentDataBinding[z].entity ==
                  'global_variable'
                ) {
                  componentRef.setInput(
                    sections.components[j].componentDataBinding[z].key,
                    this.globalVariablesService.globalVariable[
                      sections.components[j].componentDataBinding[z]
                        .entityVariable
                    ]
                  );
                } else if (
                  sections.components[j].componentDataBinding[
                    z
                  ].entity.includes('local_entity$')
                ) {
                  componentRef.setInput(
                    sections.components[j].componentDataBinding[z].key,
                    this.uiSharedService.selectedPage.localVariable[
                      sections.components[j].componentDataBinding[
                        z
                      ].entity.replace('local_entity$', '')
                    ].data[
                      sections.components[j].componentDataBinding[z]
                        .entityVariable
                    ]
                  );
                } else if (
                  sections.components[j].componentDataBinding[z].entity ==
                  'local_variable'
                ) {
                  componentRef.setInput(
                    sections.components[j].componentDataBinding[z].key,
                    this.uiSharedService.selectedPage.localVariable[
                      sections.components[j].componentDataBinding[z]
                        .entityVariable
                    ]
                  );
                } else {
                  for (
                    let i = 0;
                    i <
                    this.uiSharedService.newEntityTransformedListForSelected
                      .length;
                    i++
                  ) {
                    if (
                      this.uiSharedService.newEntityTransformedListForSelected[
                        i
                      ].id ==
                      sections.components[j].componentDataBinding[z].entity
                    ) {
                      componentRef.setInput(
                        sections.components[j].componentDataBinding[z].key,
                        this.uiSharedService
                          .newEntityTransformedListForSelected[i].data[
                          sections.components[j].componentDataBinding[z]
                            .entityVariable
                        ]
                      );
                    }
                  }
                }
              }
            }
            if (
              sections.components[j].componentDataBinding[z].objectType ==
              'output'
            ) {
              const bindingData =
                sections.components[j].componentDataBinding[z];
              const subscrip: Subscription = componentRef.instance[
                sections.components[j].componentDataBinding[z].key
              ].subscribe((newValue: any) => {
                if (bindingData.entityVariable !== '') {
                  if (bindingData.entity.includes('global_entity$')) {
                    this.uiSharedService.selectedPage.localVariable[
                      bindingData.entity.replace('global_entity$', '')
                    ].data[bindingData.entityVariable] = newValue;
                  } else if (bindingData.entity == 'global_variable') {
                    this.globalVariablesService.globalVariable[
                      bindingData.entityVariable
                    ] = newValue;
                  } else if (bindingData.entity.includes('local_entity$')) {
                    this.uiSharedService.selectedPage.localVariable[
                      bindingData.entity.replace('local_entity$', '')
                    ].data[bindingData.entityVariable] = newValue;
                  } else if (bindingData.entity == 'local_variable') {
                    this.uiSharedService.selectedPage.localVariable[
                      bindingData.entityVariable
                    ] = newValue;
                  } else {
                    for (
                      let i = 0;
                      i <
                      this.uiSharedService.newEntityTransformedListForSelected
                        .length;
                      i++
                    ) {
                      if (
                        this.uiSharedService
                          .newEntityTransformedListForSelected[i].id ==
                        bindingData.entity
                      ) {
                        this.uiSharedService.newEntityTransformedListForSelected[
                          i
                        ].data[bindingData.entityVariable] = newValue;
                      }
                    }
                  }
                }
                if (bindingData.functions[0])
                  this.runInternalFunction(bindingData.functions[0]);
                this.updateChildComponents();
              });
              subscriptionRef.push(subscrip);
            }
          }
          this.dataChangeSubscription.push(subscriptionRef);

          this.componentRefsData.push(sections.components[j]);
          this.componentRefs.push(componentRef);
          //? to be used in main page for data-binding
        }
        indexing++;
      } else {
        indexing = await this.recersiveForComponentsInNestedSections(
          sections.components[j],
          indexing
        );
      }
    }
    return indexing;
  }
  async runInternalFunction(functionName: string) {
    for (
      let i = 0;
      i < this.uiSharedService.selectedPage.functions.length;
      i++
    ) {
      if (this.uiSharedService.selectedPage.functions[i].name == functionName) {
        var functionString = this.uiSharedService.selectedPage.functions[
          i
        ].code.replaceAll(
          'localVariable$',
          'this.uiSharedService.selectedPage.localVariable.'
        );
        functionString = functionString.replaceAll(
          'globalVariable$',
          'this.globalVariablesService.globalVariable.'
        );

        for (
          let i = 0;
          i < this.uiSharedService.newEntityTransformedListForSelected.length;
          i++
        ) {
          if (
            functionString.includes(
              this.uiSharedService.newEntityTransformedListForSelected[i].id +
                '$'
            )
          )
            functionString = functionString.replaceAll(
              this.uiSharedService.newEntityTransformedListForSelected[i].id +
                '$',
              'this.uiSharedService.newEntityTransformedListForSelected[' +
                i +
                '].data'
            );
        }
        functionString = functionString.replaceAll(
          'global_entity$',
          'this.globalVariablesService.globalVariable.'
        );
        functionString = functionString.replaceAll(
          'local_entity$',
          'this.uiSharedService.selectedPage.localVariable.'
        );

        functionString = functionString.replaceAll('$', '.');
        // console.log(functionString);
        //! need to try and use the new Function instead of eval
        const func = new Function(`
return async function() {
  ${functionString}
}
`)
          .call(this)
          .bind(this);

        try {
          await func();
          // console.log(this.globalVariablesService.globalVariable);
          // console.log(this.uiSharedService.newEntityTransformedListForSelected);
        } catch (error) {
          console.log('InternlaFunctionError:' + error);
        }

        // console.log('madhukar');
        // try{
        // // eval(func());
        // } catch(error) {
        //   console.log('ERROR:',error);
        // }
        break;
      }
    }
  }
  //? to be used in main page for data-binding
  updateChildComponents() {
    for (let c = 0; c < this.componentRefsData.length; c++) {
      var outputIndex = 0;
      for (
        let z = 0;
        z < this.componentRefsData[c].componentDataBinding.length;
        z++
      ) {
        if (
          this.componentRefsData[c].componentDataBinding[z].objectType ==
          'input'
        ) {
          if (
            this.componentRefsData[c].componentDataBinding[z].type == 'static'
          ) {
            this.componentRefs[c].setInput(
              this.componentRefsData[c].componentDataBinding[z].key,
              this.componentRefsData[c].componentDataBinding[z].value
            );
          } else if (
            this.componentRefsData[c].componentDataBinding[z].type == 'dynamic'
          ) {
            if (
              this.componentRefsData[c].componentDataBinding[z].entity.includes(
                'global_entity$'
              )
            ) {
              this.componentRefs[c].setInput(
                this.componentRefsData[c].componentDataBinding[z].key,
                this.globalVariablesService.globalVariable[
                  this.componentRefsData[c].componentDataBinding[
                    z
                  ].entity.replace('global_entity$', '')
                ].data[
                  this.componentRefsData[c].componentDataBinding[z]
                    .entityVariable
                ]
              );
            } else if (
              this.componentRefsData[c].componentDataBinding[z].entity ==
              'global_variable'
            ) {
              this.componentRefs[c].setInput(
                this.componentRefsData[c].componentDataBinding[z].key,
                this.globalVariablesService.globalVariable[
                  this.componentRefsData[c].componentDataBinding[z]
                    .entityVariable
                ]
              );
            } else if (
              this.componentRefsData[c].componentDataBinding[z].entity.includes(
                'local_entity$'
              )
            ) {
              this.componentRefs[c].setInput(
                this.componentRefsData[c].componentDataBinding[z].key,
                this.uiSharedService.selectedPage.localVariable[
                  this.componentRefsData[c].componentDataBinding[
                    z
                  ].entity.replace('local_entity$', '')
                ].data[
                  this.componentRefsData[c].componentDataBinding[z]
                    .entityVariable
                ]
              );
            } else if (
              this.componentRefsData[c].componentDataBinding[z].entity ==
              'local_variable'
            ) {
              this.componentRefs[c].setInput(
                this.componentRefsData[c].componentDataBinding[z].key,
                this.uiSharedService.selectedPage.localVariable[
                  this.componentRefsData[c].componentDataBinding[z]
                    .entityVariable
                ]
              );
            } else {
              for (
                let i = 0;
                i <
                this.uiSharedService.newEntityTransformedListForSelected.length;
                i++
              ) {
                if (
                  this.uiSharedService.newEntityTransformedListForSelected[i]
                    .id ==
                  this.componentRefsData[c].componentDataBinding[z].entity
                ) {
                  this.componentRefs[c].setInput(
                    this.componentRefsData[c].componentDataBinding[z].key,
                    this.uiSharedService.newEntityTransformedListForSelected[i]
                      .data[
                      this.componentRefsData[c].componentDataBinding[z]
                        .entityVariable
                    ]
                  );
                }
              }
            }
          }
        }
      }
    }
  }
  // component data binding and styling with attributes
  //! not required in the main apge only for editor
  updateChildComponentsByIndex() {
    var outputIndex = 0;
    for (
      let z = 0;
      z < this.selectedComponent.componentDataBinding.length;
      z++
    ) {
      if (
        this.selectedComponent.componentDataBinding[z].objectType == 'input'
      ) {
        if (this.selectedComponent.componentDataBinding[z].type == 'static') {
          this.componentRefs[
            this.selectedComponentIndex +
              this.numberOfComponentsInPreviousSections
          ].setInput(
            this.selectedComponent.componentDataBinding[z].key,
            this.selectedComponent.componentDataBinding[z].value
          );
        } else if (
          this.selectedComponent.componentDataBinding[z].type == 'dynamic'
        ) {
          if (
            this.selectedComponent.componentDataBinding[z].entity.includes(
              'global_entity$'
            )
          ) {
            this.componentRefs[
              this.selectedComponentIndex +
                this.numberOfComponentsInPreviousSections
            ].setInput(
              this.selectedComponent.componentDataBinding[z].key,
              this.globalVariablesService.globalVariable[
                this.selectedComponent.componentDataBinding[z].entity.replace(
                  'global_entity$',
                  ''
                )
              ].data[
                this.selectedComponent.componentDataBinding[z].entityVariable
              ]
            );
          } else if (
            this.selectedComponent.componentDataBinding[z].entity ==
            'global_variable'
          ) {
            this.componentRefs[
              this.selectedComponentIndex +
                this.numberOfComponentsInPreviousSections
            ].setInput(
              this.selectedComponent.componentDataBinding[z].key,
              this.globalVariablesService.globalVariable[
                this.selectedComponent.componentDataBinding[z].entityVariable
              ]
            );
          } else if (
            this.selectedComponent.componentDataBinding[z].entity.includes(
              'local_entity$'
            )
          ) {
            this.componentRefs[
              this.selectedComponentIndex +
                this.numberOfComponentsInPreviousSections
            ].setInput(
              this.selectedComponent.componentDataBinding[z].key,
              this.uiSharedService.selectedPage.localVariable[
                this.selectedComponent.componentDataBinding[z].entity.replace(
                  'local_entity$',
                  ''
                )
              ].data[
                this.selectedComponent.componentDataBinding[z].entityVariable
              ]
            );
          } else if (
            this.selectedComponent.componentDataBinding[z].entity ==
            'local_variable'
          ) {
            this.componentRefs[
              this.selectedComponentIndex +
                this.numberOfComponentsInPreviousSections
            ].setInput(
              this.selectedComponent.componentDataBinding[z].key,
              this.uiSharedService.selectedPage.localVariable[
                this.selectedComponent.componentDataBinding[z].entityVariable
              ]
            );
          } else {
            for (
              let i = 0;
              i <
              this.uiSharedService.newEntityTransformedListForSelected.length;
              i++
            ) {
              if (
                this.uiSharedService.newEntityTransformedListForSelected[i]
                  .id == this.selectedComponent.componentDataBinding[z].entity
              ) {
                this.componentRefs[
                  this.selectedComponentIndex +
                    this.numberOfComponentsInPreviousSections
                ].setInput(
                  this.selectedComponent.componentDataBinding[z].key,
                  this.uiSharedService.newEntityTransformedListForSelected[i]
                    .data[
                    this.selectedComponent.componentDataBinding[z]
                      .entityVariable
                  ]
                );
              }
            }
          }
        }
      } else if (
        this.selectedComponent.componentDataBinding[z].objectType == 'output'
      ) {
        if (
          this.selectedComponent.componentDataBinding[z].entityVariable !== ''
        ) {
          // if(this.selectedComponent.componentDataBinding[z].entity == 'local_variable') {
          this.dataChangeSubscription[
            this.selectedComponentIndex +
              this.numberOfComponentsInPreviousSections
          ][outputIndex].unsubscribe();
          const bindingData = this.selectedComponent.componentDataBinding[z];
          this.dataChangeSubscription[
            this.selectedComponentIndex +
              this.numberOfComponentsInPreviousSections
          ][outputIndex] = this.componentRefs[
            this.selectedComponentIndex +
              this.numberOfComponentsInPreviousSections
          ].instance[
            this.selectedComponent.componentDataBinding[z].key
          ].subscribe((newValue: any) => {
            if (bindingData.entityVariable !== '') {
              if (bindingData.entity.includes('global_entity$')) {
                this.globalVariablesService.globalVariable[
                  bindingData.entity.replace('global_entity$', '')
                ].data[bindingData.entityVariable] = newValue;
              } else if (bindingData.entity == 'global_variable') {
                this.globalVariablesService.globalVariable[
                  bindingData.entityVariable
                ] = newValue;
              } else if (bindingData.entity.includes('local_entity$')) {
                this.uiSharedService.selectedPage.localVariable[
                  bindingData.entity.replace('local_entity$', '')
                ].data[bindingData.entityVariable] = newValue;
              } else if (bindingData.entity == 'local_variable') {
                this.uiSharedService.selectedPage.localVariable[
                  bindingData.entityVariable
                ] = newValue;
              } else {
                for (
                  let i = 0;
                  i <
                  this.uiSharedService.newEntityTransformedListForSelected
                    .length;
                  i++
                ) {
                  if (
                    this.uiSharedService.newEntityTransformedListForSelected[i]
                      .id ==
                    this.selectedComponent.componentDataBinding[z].entity
                  ) {
                    this.uiSharedService.newEntityTransformedListForSelected[
                      i
                    ].data[bindingData.entityVariable] = newValue;
                  }
                }
              }
            }

            if (bindingData.functions[0])
              this.runInternalFunction(bindingData.functions[0]);
            this.updateChildComponents();
          });
          outputIndex++;
          // }
        }
      }
    }
  }
  changeSelectedComponentType(value: string, index: number) {
    this.selectedComponent.componentDataBinding[index].type = value;
    this.updateChildComponentsByIndex();
  }
  changeSelectedComponentTypeHidden(value: string) {
    this.selectedComponent.hidden.type = value;
    this.updateChildComponentsByIndex();
  }
  dynamicEntityChanged(index: number) {
    this.selectedComponent.componentDataBinding[index].entityVariable = '';
    if (
      this.selectedComponent.componentDataBinding[index].entity.includes(
        'global_entity$'
      )
    ) {
      for (let i = 0; i < this.pageService.globalEntityList.length; i++) {
        if (
          this.pageService.globalEntityList[i].id ==
          this.selectedComponent.componentDataBinding[index].entity
        ) {
          for (const key of Object.keys(
            this.pageService.globalEntityList[i].data
          )) {
            if (
              this.getType(this.pageService.globalEntityList[i].data[key]) ==
              this.selectedComponent.componentDataBinding[index].allowedType
            ) {
              this.selectedComponent.componentDataBinding[
                index
              ].entityVariable = key;
              this.dynamicVaraibleChanged();
              return true;
            } else if (
              this.selectedComponent.componentDataBinding[index].allowedType ==
                'ENUM' &&
              this.getType(this.pageService.globalEntityList[i].data[key]) ==
                'STRING'
            ) {
              this.selectedComponent.componentDataBinding[
                index
              ].entityVariable = key;
              this.dynamicVaraibleChanged();
              return true;
            }
          }
        }
      }
      if (
        this.selectedComponent.componentDataBinding[index].entityVariable == ''
      ) {
        alert(
          'no variable of type: ' +
            this.selectedComponent.componentDataBinding[index].allowedType +
            ' present in Entity: ' +
            this.selectedComponent.componentDataBinding[index].entity
        );

        setTimeout(() => {
          this.selectedComponent.componentDataBinding[index].entity = '';
        }, 100);
      }
    } else if (
      this.selectedComponent.componentDataBinding[index].entity ==
      'global_variable'
    ) {
      for (const key of Object.keys(
        this.globalVariablesService.globalVariable
      )) {
        if (
          this.getType(this.globalVariablesService.globalVariable[key]) ==
          this.selectedComponent.componentDataBinding[index].allowedType
        ) {
          this.selectedComponent.componentDataBinding[index].entityVariable =
            key;
          this.dynamicVaraibleChanged();
          return true;
        } else if (
          this.getType(this.globalVariablesService.globalVariable[key]) ==
            'STRING' &&
          this.selectedComponent.componentDataBinding[index].allowedType ==
            'ENUM'
        ) {
          this.selectedComponent.componentDataBinding[index].entityVariable =
            key;
          this.dynamicVaraibleChanged();
          return true;
        }
      }
      if (
        this.selectedComponent.componentDataBinding[index].entityVariable == ''
      ) {
        alert(
          'no variable of type: ' +
            this.selectedComponent.componentDataBinding[index].allowedType +
            ' present in Local Variable'
        );
        setTimeout(() => {
          this.selectedComponent.componentDataBinding[index].entity = '';
        }, 100);
      }
    } else if (
      this.selectedComponent.componentDataBinding[index].entity.includes(
        'local_entity$'
      )
    ) {
      for (let i = 0; i < this.uiSharedService.localEntityList.length; i++) {
        if (
          this.uiSharedService.localEntityList[i].id ==
          this.selectedComponent.componentDataBinding[index].entity
        ) {
          for (const key of Object.keys(
            this.uiSharedService.localEntityList[i].data
          )) {
            if (
              this.getType(this.uiSharedService.localEntityList[i].data[key]) ==
              this.selectedComponent.componentDataBinding[index].allowedType
            ) {
              this.selectedComponent.componentDataBinding[
                index
              ].entityVariable = key;
              this.dynamicVaraibleChanged();
              return true;
            } else if (
              this.getType(this.uiSharedService.localEntityList[i].data[key]) ==
                'STRING' &&
              this.selectedComponent.componentDataBinding[index].allowedType ==
                'STRING'
            ) {
              this.selectedComponent.componentDataBinding[
                index
              ].entityVariable = key;
              this.dynamicVaraibleChanged();
              return true;
            }
          }
        }
      }
      if (
        this.selectedComponent.componentDataBinding[index].entityVariable == ''
      ) {
        alert(
          'no variable of type: ' +
            this.selectedComponent.componentDataBinding[index].allowedType +
            ' present in Entity: ' +
            this.selectedComponent.componentDataBinding[index].entity
        );

        setTimeout(() => {
          this.selectedComponent.componentDataBinding[index].entity = '';
        }, 100);
      }
    } else if (
      this.selectedComponent.componentDataBinding[index].entity ==
      'local_variable'
    ) {
      for (const key of Object.keys(
        this.uiSharedService.selectedPage.localVariable
      )) {
        if (
          this.getType(this.uiSharedService.selectedPage.localVariable[key]) ==
          this.selectedComponent.componentDataBinding[index].allowedType
        ) {
          this.selectedComponent.componentDataBinding[index].entityVariable =
            key;
          this.dynamicVaraibleChanged();
          return true;
        } else if (
          this.getType(this.uiSharedService.selectedPage.localVariable[key]) ==
            'STRING' &&
          this.selectedComponent.componentDataBinding[index].allowedType ==
            'ENUM'
        ) {
          this.selectedComponent.componentDataBinding[index].entityVariable =
            key;
          this.dynamicVaraibleChanged();
          return true;
        }
      }
      if (
        this.selectedComponent.componentDataBinding[index].entityVariable == ''
      ) {
        alert(
          'no variable of type: ' +
            this.selectedComponent.componentDataBinding[index].allowedType +
            ' present in Local Variable'
        );
        setTimeout(() => {
          this.selectedComponent.componentDataBinding[index].entity = '';
        }, 100);
      }
    } else {
      for (
        let i = 0;
        i < this.uiSharedService.newEntityTransformedListForSelected.length;
        i++
      ) {
        if (
          this.uiSharedService.newEntityTransformedListForSelected[i].id ==
          this.selectedComponent.componentDataBinding[index].entity
        ) {
          for (const key of Object.keys(
            this.uiSharedService.newEntityTransformedListForSelected[i].data
          )) {
            if (
              this.getType(
                this.uiSharedService.newEntityTransformedListForSelected[i]
                  .data[key]
              ) ==
              this.selectedComponent.componentDataBinding[index].allowedType
            ) {
              this.selectedComponent.componentDataBinding[
                index
              ].entityVariable = key;
              this.dynamicVaraibleChanged();
              return true;
            } else if (
              this.selectedComponent.componentDataBinding[index].allowedType ==
                'ENUM' &&
              this.getType(
                this.uiSharedService.newEntityTransformedListForSelected[i]
                  .data[key]
              ) == 'STRING'
            ) {
              this.selectedComponent.componentDataBinding[
                index
              ].entityVariable = key;
              this.dynamicVaraibleChanged();
              return true;
            }
          }
        }
      }
      if (
        this.selectedComponent.componentDataBinding[index].entityVariable == ''
      ) {
        alert(
          'no variable of type: ' +
            this.selectedComponent.componentDataBinding[index].allowedType +
            ' present in Entity: ' +
            this.selectedComponent.componentDataBinding[index].entity
        );

        setTimeout(() => {
          this.selectedComponent.componentDataBinding[index].entity = '';
        }, 100);
      }
    }
    return true;
  }
  dynamicEntityChangedHidden() {
    this.selectedComponent.hidden.entityVariable = '';
    if (this.selectedComponent.hidden.entity.includes('local_entity$')) {
      for (let i = 0; i < this.uiSharedService.localEntityList.length; i++) {
        if (
          this.uiSharedService.localEntityList[i].id ==
          this.selectedComponent.hidden.entity
        ) {
          for (let key of Object.keys(
            this.uiSharedService.localEntityList[i].data
          )) {
            if (
              this.getType(this.uiSharedService.localEntityList[i].data[key]) ==
              this.selectedComponent.hidden.allowedType
            ) {
              this.selectedComponent.hidden.entityVariable = key;
              this.dynamicVaraibleChanged();
              return true;
            }
          }
        }
      }
      if (this.selectedComponent.hidden.entityVariable == '') {
        alert(
          'no variable of type: ' +
            this.selectedComponent.hidden.allowedType +
            ' present in Entity: ' +
            this.selectedComponent.hidden.entity
        );

        setTimeout(() => {
          this.selectedComponent.hidden.entity = '';
        }, 100);
      }
    } else if (this.selectedComponent.hidden.entity == 'local_variable') {
      for (const key of Object.keys(
        this.uiSharedService.selectedPage.localVariable
      )) {
        if (
          this.getType(this.uiSharedService.selectedPage.localVariable[key]) ==
          this.selectedComponent.hidden.allowedType
        ) {
          this.selectedComponent.hidden.entityVariable = key;
          this.dynamicVaraibleChanged();
          return true;
        }
      }
      if (this.selectedComponent.hidden.entityVariable == '') {
        alert(
          'no variable of type: ' +
            this.selectedComponent.hidden.allowedType +
            ' present in Local Variable'
        );
        setTimeout(() => {
          this.selectedComponent.hidden.entity = '';
        }, 100);
      }
    } else {
      for (
        let i = 0;
        i < this.uiSharedService.newEntityTransformedListForSelected.length;
        i++
      ) {
        if (
          this.uiSharedService.newEntityTransformedListForSelected[i].id ==
          this.selectedComponent.hidden.entity
        ) {
          for (let key of Object.keys(
            this.uiSharedService.newEntityTransformedListForSelected[i].data
          )) {
            if (
              this.getType(
                this.uiSharedService.newEntityTransformedListForSelected[i]
                  .data[key]
              ) == this.selectedComponent.hidden.allowedType
            ) {
              this.selectedComponent.hidden.entityVariable = key;
              this.dynamicVaraibleChanged();
              return true;
            }
          }
        }
      }
      if (this.selectedComponent.hidden.entityVariable == '') {
        alert(
          'no variable of type: ' +
            this.selectedComponent.hidden.allowedType +
            ' present in Entity: ' +
            this.selectedComponent.hidden.entity
        );

        setTimeout(() => {
          this.selectedComponent.hidden.entity = '';
        }, 100);
      }
    }
    return true;
  }
  selectedFunctionName = '';
  dynamicFunctionChanged(index: number) {
    var flag = false;
    if (this.selectedFunctionName !== '') {
      for (
        let i = 0;
        i < this.uiSharedService.selectedPage.functions.length;
        i++
      ) {
        if (
          this.selectedFunctionName ==
          this.uiSharedService.selectedPage.functions[i].name
        ) {
          //! need to change to push as need to be able to add multiple funcitons to a output
          this.selectedComponent.componentDataBinding[index].functions[0] =
            this.uiSharedService.selectedPage.functions[i].name;
          this.selectedFunctionName = '';
          flag = true;
          break;
        }
      }
    }
    if (!flag) {
      const name = '' + this.selectedFunctionName;
      this.selectedFunctionName = '';
      alert('no funciton found with the name: ' + name);
    }
  }
  dynamicVaraibleChanged(index: number = 0) {
    this.updateChildComponentsByIndex();
  }
  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }
  getType(variable: any): string {
    if (variable === null) {
      return 'null';
    }
    if (Array.isArray(variable)) {
      return 'array';
    }
    var type = typeof variable;
    if (type == 'boolean') return 'BOOLEAN';
    if (type == 'string') return 'STRING';
    if (type == 'number') return 'NUMBER';
    return type;
  }

  // ! has to be removed just kept for safety : please remove if everything works fine
  // changeSampleData(newData: string) {
  //   this.sampleData = newData;
  //   this.updateChildComponents();
  // }
  // ! has to be removed just kept for safety : please remove if everything works fine
  // end of add components to ng-content
  //? to be used in main page for data-binding
  @ViewChildren('parent', { read: ViewContainerRef })
  parent!: QueryList<ViewContainerRef>;
  // ! has to be removed just kept for safety : please remove if everything works fine
  // private getContainerRef(containerId: string): ViewContainerRef {
  //   var container = null;
  //   this.parent.forEach((item) =>
  //   {
  // if( item.element.nativeElement.id === containerId)
  // {
  //   container = item;
  // }
  //   }
  //   );
  //   if (!container) {
  //     throw new Error(`Container with id '${containerId}' not found.`);
  //   }
  //   return container;
  // }
  // ! has to be removed just kept for safety : please remove if everything works fine
  // for selectedSectionIndexChange
  selectedSection: any = {
    name: 'dummy',
    id: 'dummy',
    type: 'section',
    styles: '',
    components: [],
  };
  selectedParentSection: any = {
    name: 'dummy',
    id: 'dummy',
    type: 'section',
    styles: '',
    components: [],
  };
  selectedSectionIndexInParent: number = -1;
  gapStyleForSection = '';
  paddingLeftStyleForSection = '';
  paddingRightStyleForSection = '';
  paddingTopStyleForSection = '';
  paddingBottomStyleForSection = '';
  marginLeftStyleForSection = '';
  marginRightStyleForSection = '';
  marginTopStyleForSection = '';
  marginBottomStyleForSection = '';
  backgroundColorStyleForSection = '';
  roundedStyleForSection = '';
  onlyIndexChangeOfComponentAndSectionFlag = false;
  async selectedSectionIndexChanged(event: any) {
    this.onlyIndexChangeOfComponentAndSectionFlag = false;
    if (this.selectedSectionIndex[1] == -1) {
      this.selectedSection =
        this.uiSharedService.selectedPage.sections[
          this.selectedSectionIndex[0]
        ];
      this.selectedSectionIndexInParent = -1;
    } else {
      var selectedSections = this.recersiveNumbersForSelectedSection(
        0,
        this.uiSharedService.selectedPage.sections[this.selectedSectionIndex[0]]
      );
      this.selectedSection = selectedSections.section;
      this.selectedParentSection = selectedSections.parentSection;
      this.selectedSectionIndexInParent = selectedSections.indexNumberInParent;
    }
    //! needs to be removed in prod but needed for development
    // for gap
    const gapPxRegex: RegExp = /gap-\[(\d+)px\]/;
    const matcheGap = this.selectedSection?.styles.match(gapPxRegex);
    if (matcheGap && matcheGap[1]) {
      this.gapStyleForSection = matcheGap[1];
    } else {
      this.gapStyleForSection = '0';
    }
    // for padding left
    const leftPaddingPxRegex: RegExp = /pl-\[(\d+)px\]/;
    const matcheLeftPadding =
      this.selectedSection?.styles.match(leftPaddingPxRegex);
    if (matcheLeftPadding && matcheLeftPadding[1]) {
      this.paddingLeftStyleForSection = matcheLeftPadding[1];
    } else {
      this.paddingLeftStyleForSection = '0';
    }
    // padding-right
    const rightPaddingPxRegex: RegExp = /pr-\[(\d+)px\]/;
    const matcheRightPadding =
      this.selectedSection?.styles.match(rightPaddingPxRegex);
    if (matcheRightPadding && matcheRightPadding[1]) {
      this.paddingRightStyleForSection = matcheRightPadding[1];
    } else {
      this.paddingRightStyleForSection = '0';
    }
    // padding-top
    const topPaddingPxRegex: RegExp = /pt-\[(\d+)px\]/;
    const matcheTopPadding =
      this.selectedSection?.styles.match(topPaddingPxRegex);
    if (matcheTopPadding && matcheTopPadding[1]) {
      this.paddingTopStyleForSection = matcheTopPadding[1];
    } else {
      this.paddingTopStyleForSection = '0';
    }
    // padding-bottom
    const bottomPaddingPxRegex: RegExp = /pb-\[(\d+)px\]/;
    const matcheBottomPadding =
      this.selectedSection?.styles.match(bottomPaddingPxRegex);
    if (matcheBottomPadding && matcheBottomPadding[1]) {
      this.paddingBottomStyleForSection = matcheBottomPadding[1];
    } else {
      this.paddingBottomStyleForSection = '0';
    }
    // for margin left
    const leftmarginPxRegex: RegExp = /ml-\[(\d+)px\]/;
    const matcheLeftmargin =
      this.selectedSection?.styles.match(leftmarginPxRegex);
    if (matcheLeftmargin && matcheLeftmargin[1]) {
      this.marginLeftStyleForSection = matcheLeftmargin[1];
    } else {
      this.marginLeftStyleForSection = '0';
    }
    // margin-right
    const rightmarginPxRegex: RegExp = /mr-\[(\d+)px\]/;
    const matcheRightmargin =
      this.selectedSection?.styles.match(rightmarginPxRegex);
    if (matcheRightmargin && matcheRightmargin[1]) {
      this.marginRightStyleForSection = matcheRightmargin[1];
    } else {
      this.marginRightStyleForSection = '0';
    }
    // margin-top
    const topmarginPxRegex: RegExp = /mt-\[(\d+)px\]/;
    const matcheTopmargin =
      this.selectedSection?.styles.match(topmarginPxRegex);
    if (matcheTopmargin && matcheTopmargin[1]) {
      this.marginTopStyleForSection = matcheTopmargin[1];
    } else {
      this.marginTopStyleForSection = '0';
    }
    // margin-bottom
    const bottommarginPxRegex: RegExp = /mb-\[(\d+)px\]/;
    const matcheBottommargin =
      this.selectedSection?.styles.match(bottommarginPxRegex);
    if (matcheBottommargin && matcheBottommargin[1]) {
      this.marginBottomStyleForSection = matcheBottommargin[1];
    } else {
      this.marginBottomStyleForSection = '0';
    }
    // background-color
    const backgroundColorRegex: RegExp = /bg-\[([^\]]+)\]/;
    const matcheBackgroundColor =
      this.selectedSection?.styles.match(backgroundColorRegex);
    if (matcheBackgroundColor && matcheBackgroundColor[1]) {
      this.backgroundColorStyleForSection = matcheBackgroundColor[1];
    } else {
      this.backgroundColorStyleForSection = '';
    }
     //rounded
     const roundedRegex: RegExp = /rounded-([a-z]+)/;

     const matchedRounded = this.selectedSection?.styles.match(roundedRegex);
     if (matchedRounded && matchedRounded[1]) {
       this.roundedStyleForSection = matchedRounded[1];
     } else {
       this.roundedStyleForSection = '';
     }
    //! needs to be removed in prod but needed for development'
    setTimeout(async () => {
      await this.childComponents
        ?.toArray()
        .forEach((childComponent: ComponentsComponent) => {
          childComponent.highlightSectionFlagFunction();
        });
    }, 100);
  }

  // assigned the selected section to a variable for further use
  recersiveNumbersForSelectedSection(
    matchNumber: number,
    sections: Sections | Components
  ) {
    var indexNumberReturned = -1;
    if (matchNumber == this.selectedSectionIndex[1]) {
      const returnData = {
        matchNumber: matchNumber,
        flag: true,
        parentFlag: true,
        indexNumberInParent: 0,
        parentSection: sections,
        section: sections,
      };
      return returnData;
    }
    for (let i = 0; i < sections.components.length; i++) {
      if (sections.components[i].type == 'section') {
        matchNumber++;
        var returnedData = this.recersiveNumbersForSelectedSection(
          matchNumber,
          sections.components[i]
        );
        matchNumber = returnedData.matchNumber;
        const sectionsReturned: any = returnedData.section;
        const paretnSectionsReturned: any = returnedData.parentSection;
        if (returnedData.flag) {
          if (returnedData.parentFlag) {
            const returnData = {
              matchNumber: matchNumber,
              indexNumberInParent: i,
              flag: true,
              parentFlag: false,
              parentSection: sections,
              section: sectionsReturned,
            };
            return returnData;
          } else {
            const indexReturned: any = returnedData.indexNumberInParent;
            indexNumberReturned = indexReturned;
            const returnData = {
              matchNumber: matchNumber,
              indexNumberInParent: indexReturned,
              flag: true,
              parentFlag: false,
              parentSection: paretnSectionsReturned,
              section: sectionsReturned,
            };
            return returnData;
          }
        }
      }
    }
    const returnData = {
      matchNumber: matchNumber,
      flag: false,
      parentFlag: false,
      indexNumberInParent: indexNumberReturned,
      parentSection: sections,
      section: sections,
    };
    return returnData;
  }
  // end of selecting the selected section
  // for selectedComponentIndexChanged
  selectedComponent: Components = {
    name: 'dummy',
    id: 'dummy',
    type: 'component',
    styles: '',
    hidden: {
      type: 'static',
      entity: '',
      entityVariable: '',
      allowedType: 'BOOLEAN',
      value: false,
    },
    components: [],
    componentDataBinding: [],
  };
  selectedComponentIndexInParent: any;
  numberOfComponentsInPreviousSections: number = 0;
  async selectedComponentIndexChanged(x: any) {
    this.numberOfComponentsInPreviousSections = 0;
    for (
      let i = 0;
      i < this.uiSharedService.selectedPage.sections.length;
      i++
    ) {
      if (this.selectedSectionIndex[0] == i) {
        break;
      }
      this.numberOfComponentsInPreviousSections =
        this.numberOfComponentsInPreviousSections +
        this.recersiveNumbersForSelectedComponent(
          0,
          this.uiSharedService.selectedPage.sections[i],
          -1
        ).incrementIndexChange; // the selectedComponentIndex will never match hence we will get the toatl number of components in this section
    }
    this.onlyIndexChangeOfComponentAndSectionFlag = false;
    this.selectedComponentIndexInParent = -1;
    const selectedComponent = this.recersiveNumbersForSelectedComponent(
      0,
      this.uiSharedService.selectedPage.sections[this.selectedSectionIndex[0]],
      this.selectedComponentIndex
    );
    this.selectedComponent = selectedComponent.component;
    this.selectedComponentIndexInParent = selectedComponent.indexNumberInParent;
    setTimeout(async () => {
      await this.childComponents
        ?.toArray()
        .forEach((childComponent: ComponentsComponent, index: number) => {
          childComponent.getCompoundComponentNumber();
        });
    }, 100);
  }

  // assigned the selected component to a variable for further use
  recersiveNumbersForSelectedComponent(
    incrementIndex: number,
    section: Components | Sections,
    selectedComponentIndex: number
  ) {
    for (let i = 0; i < section.components.length; i++) {
      if (section.components[i].type == 'component') {
        if (incrementIndex == selectedComponentIndex) {
          const returnData = {
            incrementIndexChange: incrementIndex,
            component: section.components[i],
            flag: true,
            indexNumberInParent: i,
          };
          return returnData;
        }
        incrementIndex++;
      } else {
        var returnedData: any = this.recersiveNumbersForSelectedComponent(
          incrementIndex,
          section.components[i],
          selectedComponentIndex
        );

        incrementIndex = returnedData.incrementIndexChange;
        const indexNumberInParent = returnedData.indexNumberInParent;
        if (returnedData.flag) {
          const returnData = {
            incrementIndexChange: incrementIndex,
            component: returnedData.component,
            flag: true,
            indexNumberInParent: indexNumberInParent,
          };
          return returnData;
        }
      }
    }
    const returnData = {
      incrementIndexChange: incrementIndex,
      flag: false,
      component: null,
      indexNumberInParent: 0,
    };
    return returnData;
  }
  // end of assign seelcted component
  returnHiddenFlagForSection(index: number) {
    if (
      this.uiSharedService.selectedPage.sections[index].hidden.type == 'dynamic'
    ) {
      if (
        this.uiSharedService.selectedPage.sections[
          index
        ].hidden.entity.includes('global_entity$')
      ) {
        return this.globalVariablesService.globalVariable[
          this.uiSharedService.selectedPage.sections[
            index
          ].hidden.entity.replace('global_entity$', '')
        ].data[
          this.uiSharedService.selectedPage.sections[index].hidden
            .entityVariable
        ];
      } else if (
        this.uiSharedService.selectedPage.sections[index].hidden.entity ==
        'global_variable'
      ) {
        return this.globalVariablesService.globalVariable[
          this.uiSharedService.selectedPage.sections[index].hidden
            .entityVariable
        ];
      } else if (
        this.uiSharedService.selectedPage.sections[
          index
        ].hidden.entity.includes('local_entity$')
      ) {
        return this.uiSharedService.selectedPage.localVariable[
          this.uiSharedService.selectedPage.sections[
            index
          ].hidden.entity.replace('local_entity$', '')
        ].data[
          this.uiSharedService.selectedPage.sections[index].hidden
            .entityVariable
        ];
      } else if (
        this.uiSharedService.selectedPage.sections[index].hidden.entity ==
        'local_variable'
      ) {
        return this.uiSharedService.selectedPage.localVariable[
          this.uiSharedService.selectedPage.sections[index].hidden
            .entityVariable
        ];
      } else {
        for (
          let i = 0;
          i < this.uiSharedService.newEntityTransformedListForSelected.length;
          i++
        ) {
          if (
            this.uiSharedService.newEntityTransformedListForSelected[i].id ==
            this.selectedSection.hidden.entity
          ) {
            return this.uiSharedService.newEntityTransformedListForSelected[i]
              .data[
              this.uiSharedService.selectedPage.sections[index].hidden
                .entityVariable
            ];
          }
        }
      }
    }
    return true;
  }
  // not required in development also have to remove all styles like this in the end of saving styles

  //! needs to be removed in prod but needed for development
  returnOnlyStyle(data: string) {
    //? right now only usefull for gap
    var style = '';
    // gap
    const gapPxRegex: RegExp = /gap-\[(\d+)px\]/;
    const matcheGap = data.match(gapPxRegex);
    if (matcheGap && matcheGap[1]) {
      style = style + ' ' + 'gap: ' + matcheGap[1] + 'px;';
    }
    // left padding
    const paddingLeftPxRegex: RegExp = /pl-\[(\d+)px\]/;
    const matcheLeftPadding = data.match(paddingLeftPxRegex);
    if (matcheLeftPadding && matcheLeftPadding[1]) {
      style = style + ' ' + 'padding-left: ' + matcheLeftPadding[1] + 'px;';
    }
    // right padding
    const paddingRightPxRegex: RegExp = /pr-\[(\d+)px\]/;
    const matcheRightPadding = data.match(paddingRightPxRegex);
    if (matcheRightPadding && matcheRightPadding[1]) {
      style = style + ' ' + 'padding-right: ' + matcheRightPadding[1] + 'px;';
    }
    // top padding
    const paddingTopPxRegex: RegExp = /pt-\[(\d+)px\]/;
    const matcheTopPadding = data.match(paddingTopPxRegex);
    if (matcheTopPadding && matcheTopPadding[1]) {
      style = style + ' ' + 'padding-top: ' + matcheTopPadding[1] + 'px;';
    }
    // bottom padding
    const paddingBottomPxRegex: RegExp = /pb-\[(\d+)px\]/;
    const matcheBottomPadding = data.match(paddingBottomPxRegex);
    if (matcheBottomPadding && matcheBottomPadding[1]) {
      style = style + ' ' + 'padding-bottom: ' + matcheBottomPadding[1] + 'px;';
    }
    // left margin
    const marginLeftPxRegex: RegExp = /ml-\[(\d+)px\]/;
    const matcheLeftmargin = data.match(marginLeftPxRegex);
    if (matcheLeftmargin && matcheLeftmargin[1]) {
      style = style + ' ' + 'margin-left: ' + matcheLeftmargin[1] + 'px;';
    }
    // right margin
    const marginRightPxRegex: RegExp = /mr-\[(\d+)px\]/;
    const matcheRightmargin = data.match(marginRightPxRegex);
    if (matcheRightmargin && matcheRightmargin[1]) {
      style = style + ' ' + 'margin-right: ' + matcheRightmargin[1] + 'px;';
    }
    // top margin
    const marginTopPxRegex: RegExp = /mt-\[(\d+)px\]/;
    const matcheTopmargin = data.match(marginTopPxRegex);
    if (matcheTopmargin && matcheTopmargin[1]) {
      style = style + ' ' + 'margin-top: ' + matcheTopmargin[1] + 'px;';
    }
    // bottom margin
    const marginBottomPxRegex: RegExp = /mb-\[(\d+)px\]/;
    const matcheBottommargin = data.match(marginBottomPxRegex);
    if (matcheBottommargin && matcheBottommargin[1]) {
      style = style + ' ' + 'margin-bottom: ' + matcheBottommargin[1] + 'px;';
    }
    // background color
    const backgroundColorRegex: RegExp = /bg-\[([^\]]+)\]/;
    const backgroundColor = data.match(backgroundColorRegex);
    if (backgroundColor && backgroundColor[1]) {
      style = style + ' ' + 'background-color: ' + backgroundColor[1] + ';';
    }
    return style;
  }
  //! needs to be removed in prod but needed for development

  //? start styling
  // alter section styles

  changeSelectedSectionStylee(value: string) {
    const justifyClassesRegex = /justify-(start|center|end|between|around)/g; // Regular expression for all justify classes
    const flexWrapRegex: RegExp = /flex-wrap\s*/gi; // Regular expression for 'flex-wrap' with optional whitespace and values
    const flexGrowRegex: RegExp = /flex-grow\s*/gi;
    const flexColRegex: RegExp = /flex-col\s*/gi;
    const gapRegex: RegExp = /gap-\[(.*?)\]/g;
    const paddingLeftRegex: RegExp = /pl-\[(.*?)\]/g;
    const paddingRightRegex: RegExp = /pr-\[(.*?)\]/g;
    const paddingTopRegex: RegExp = /pt-\[(.*?)\]/g;
    const paddingBottomRegex: RegExp = /pb-\[(.*?)\]/g;
    const marginLeftRegex: RegExp = /ml-\[(.*?)\]/g;
    const marginRightRegex: RegExp = /mr-\[(.*?)\]/g;
    const marginTopRegex: RegExp = /mt-\[(.*?)\]/g;
    const marginBottomRegex: RegExp = /mb-\[(.*?)\]/g;
    const backgroundColorRegex: RegExp = /bg-\[(.*?)\]/g;
    const compressedSpacesRegex: RegExp = /\s{2,}/g;
    const roundedRegex: RegExp = /rounded-(sm|md|lg|full)/g;

    var regex: RegExp = /sample123#dontusesample/g;
    const justifyValues = {
      'justify-center': true,
      'justify-around': true,
      'justify-start': true,
      'justify-end': true,
      'justify-between': true,
    };

    if (justifyValues.hasOwnProperty(value)) {
      regex = justifyClassesRegex;
    } else if (value == 'flex-wrap') {
      regex = flexWrapRegex;
      if (this.sectionStyleIncludes('flex-wrap')) {
        value = '';
      }
    } else if (value == 'flex-grow') {
      regex = flexGrowRegex;
      if (this.sectionStyleIncludes('flex-grow')) {
        value = '';
      }
    } else if (value == 'flex-col') {
      regex = flexColRegex;
      if (this.sectionStyleIncludes('flex-col')) {
        value = '';
      }
    } else if (value == 'flex-gap') {
      regex = gapRegex;
      this.gapStyleForSection = this.gapStyleForSection.replace(/[^0-9]/g, '');
      if (this.gapStyleForSection == '') {
        this.gapStyleForSection = '0';
      }
      value = 'gap-[' + this.gapStyleForSection + 'px]';
    } else if (value == 'padding-left') {
      regex = paddingLeftRegex;
      this.paddingLeftStyleForSection = this.paddingLeftStyleForSection.replace(
        /[^0-9]/g,
        ''
      );
      if (this.paddingLeftStyleForSection == '') {
        this.paddingLeftStyleForSection = '0';
      }
      value = 'pl-[' + this.paddingLeftStyleForSection + 'px]';
    } else if (value == 'padding-right') {
      regex = paddingRightRegex;
      this.paddingRightStyleForSection =
        this.paddingRightStyleForSection.replace(/[^0-9]/g, '');
      if (this.paddingRightStyleForSection == '') {
        this.paddingRightStyleForSection = '0';
      }
      value = 'pr-[' + this.paddingRightStyleForSection + 'px]';
    } else if (value == 'padding-top') {
      regex = paddingTopRegex;
      this.paddingTopStyleForSection = this.paddingTopStyleForSection.replace(
        /[^0-9]/g,
        ''
      );
      if (this.paddingTopStyleForSection == '') {
        this.paddingTopStyleForSection = '0';
      }
      value = 'pt-[' + this.paddingTopStyleForSection + 'px]';
    } else if (value == 'padding-bottom') {
      regex = paddingBottomRegex;
      this.paddingBottomStyleForSection =
        this.paddingBottomStyleForSection.replace(/[^0-9]/g, '');
      if (this.paddingBottomStyleForSection == '') {
        this.paddingBottomStyleForSection = '0';
      }
      value = 'pb-[' + this.paddingBottomStyleForSection + 'px]';
    } else if (value == 'margin-left') {
      regex = marginLeftRegex;
      this.marginLeftStyleForSection = this.marginLeftStyleForSection.replace(
        /[^0-9]/g,
        ''
      );
      if (this.marginLeftStyleForSection == '') {
        this.marginLeftStyleForSection = '0';
      }
      value = 'ml-[' + this.marginLeftStyleForSection + 'px]';
    } else if (value == 'margin-right') {
      regex = marginRightRegex;
      this.marginRightStyleForSection = this.marginRightStyleForSection.replace(
        /[^0-9]/g,
        ''
      );
      if (this.marginRightStyleForSection == '') {
        this.marginRightStyleForSection = '0';
      }
      value = 'mr-[' + this.marginRightStyleForSection + 'px]';
    } else if (value == 'margin-top') {
      regex = marginTopRegex;
      this.marginTopStyleForSection = this.marginTopStyleForSection.replace(
        /[^0-9]/g,
        ''
      );
      if (this.marginTopStyleForSection == '') {
        this.marginTopStyleForSection = '0';
      }
      value = 'mt-[' + this.marginTopStyleForSection + 'px]';
    } else if (value == 'margin-bottom') {
      regex = marginBottomRegex;
      this.marginBottomStyleForSection =
        this.marginBottomStyleForSection.replace(/[^0-9]/g, '');
      if (this.marginBottomStyleForSection == '') {
        this.marginBottomStyleForSection = '0';
      }
      value = 'mb-[' + this.marginBottomStyleForSection + 'px]';
    } else if (value == 'background-color') {
      regex = backgroundColorRegex;
      value = 'bg-[' + this.backgroundColorStyleForSection + ']';
    } else if (value == "rounded") {
      regex = roundedRegex;
              value =  this.roundedStyleForSection;
    }
    // can add more styles here

    if (this.selectedSectionIndex[1] == -1) {
      this.uiSharedService.selectedPage.sections[
        this.selectedSectionIndex[0]
      ].styles = this.uiSharedService.selectedPage.sections[
        this.selectedSectionIndex[0]
      ].styles.replace(regex, '');
      this.uiSharedService.selectedPage.sections[
        this.selectedSectionIndex[0]
      ].styles =
        this.uiSharedService.selectedPage.sections[this.selectedSectionIndex[0]]
          .styles +
        ' ' +
        value;
      // remove double spaces
      this.uiSharedService.selectedPage.sections[
        this.selectedSectionIndex[0]
      ].styles = this.uiSharedService.selectedPage.sections[
        this.selectedSectionIndex[0]
      ].styles.replace(compressedSpacesRegex, ' ');
    } else {
      this.recersiveNumbersForSectionsToAddStyles(
        0,
        this.uiSharedService.selectedPage.sections[
          this.selectedSectionIndex[0]
        ],
        regex,
        value
      );
    }
  }

  changeSelectedSectionTypeHidden(value: string) {
    this.selectedSection.hidden.type = value;
    // this.updateChildComponentsByIndex();
  }
  dynamicEntityChangedHiddenForSection() {
    this.selectedSection.hidden.entityVariable = '';
    if (this.selectedSection.hidden.entity.includes('global_entity$')) {
      for (let i = 0; i < this.pageService.globalEntityList.length; i++) {
        if (
          this.pageService.globalEntityList[i].id ==
          this.selectedSection.hidden.entity
        ) {
          for (
            let j = 0;
            j < this.pageService.globalEntityList[i].data.length;
            j++
          ) {
            if (
              this.pageService.globalEntityList[i].data[j].type ==
              this.selectedSection.hidden.allowedType
            ) {
              this.selectedSection.hidden.entityVariable =
                this.pageService.globalEntityList[i].data[j].name;
              this.dynamicVaraibleChanged();
              return true;
            }
          }
        }
      }
      if (this.selectedSection.hidden.entityVariable == '') {
        alert(
          'no variable of type: ' +
            this.selectedSection.hidden.allowedType +
            ' present in Entity: ' +
            this.selectedSection.hidden.entity
        );

        setTimeout(() => {
          this.selectedSection.hidden.entity = '';
        }, 100);
      }
    } else if (this.selectedSection.hidden.entity == 'global_variable') {
      for (const key of Object.keys(
        this.globalVariablesService.globalVariable
      )) {
        if (
          this.getType(this.uiSharedService.selectedPage.localVariable[key]) ==
          this.selectedSection.hidden.allowedType
        ) {
          this.selectedSection.hidden.entityVariable = key;
          this.dynamicVaraibleChanged();
          return true;
        }
      }
      if (this.selectedSection.hidden.entityVariable == '') {
        alert(
          'no variable of type: ' +
            this.selectedSection.hidden.allowedType +
            ' present in Local Variable'
        );
        setTimeout(() => {
          this.selectedSection.hidden.entity = '';
        }, 100);
      }
    } else if (this.selectedSection.hidden.entity.includes('local_entity$')) {
      for (let i = 0; i < this.uiSharedService.localEntityList.length; i++) {
        if (
          this.uiSharedService.localEntityList[i].id ==
          this.selectedSection.hidden.entity
        ) {
          for (
            let j = 0;
            j < this.uiSharedService.localEntityList[i].data.length;
            j++
          ) {
            if (
              this.uiSharedService.localEntityList[i].data[j].type ==
              this.selectedSection.hidden.allowedType
            ) {
              this.selectedSection.hidden.entityVariable =
                this.uiSharedService.localEntityList[i].data[j].name;
              this.dynamicVaraibleChanged();
              return true;
            }
          }
        }
      }
      if (this.selectedSection.hidden.entityVariable == '') {
        alert(
          'no variable of type: ' +
            this.selectedSection.hidden.allowedType +
            ' present in Entity: ' +
            this.selectedSection.hidden.entity
        );

        setTimeout(() => {
          this.selectedSection.hidden.entity = '';
        }, 100);
      }
    } else if (this.selectedSection.hidden.entity == 'local_variable') {
      for (const key of Object.keys(
        this.uiSharedService.selectedPage.localVariable
      )) {
        if (
          this.getType(this.uiSharedService.selectedPage.localVariable[key]) ==
          this.selectedSection.hidden.allowedType
        ) {
          this.selectedSection.hidden.entityVariable = key;
          this.dynamicVaraibleChanged();
          return true;
        }
      }
      if (this.selectedSection.hidden.entityVariable == '') {
        alert(
          'no variable of type: ' +
            this.selectedSection.hidden.allowedType +
            ' present in Local Variable'
        );
        setTimeout(() => {
          this.selectedSection.hidden.entity = '';
        }, 100);
      }
    } else {
      for (
        let i = 0;
        i < this.uiSharedService.newEntityTransformedListForSelected.length;
        i++
      ) {
        if (
          this.uiSharedService.newEntityTransformedListForSelected[i].id ==
          this.selectedSection.hidden.entity
        ) {
          for (
            let j = 0;
            j <
            this.uiSharedService.newEntityTransformedListForSelected[i].data
              .length;
            j++
          ) {
            if (
              this.uiSharedService.newEntityTransformedListForSelected[i].data[
                j
              ].type == this.selectedSection.hidden.allowedType
            ) {
              this.selectedSection.hidden.entityVariable =
                this.uiSharedService.newEntityTransformedListForSelected[
                  i
                ].data[j].name;
              this.dynamicVaraibleChanged();
              return true;
            }
          }
        }
      }
      if (this.selectedSection.hidden.entityVariable == '') {
        alert(
          'no variable of type: ' +
            this.selectedSection.hidden.allowedType +
            ' present in Entity: ' +
            this.selectedSection.hidden.entity
        );

        setTimeout(() => {
          this.selectedSection.hidden.entity = '';
        }, 100);
      }
    }
    return true;
  }

  // regression to add styles
  recersiveNumbersForSectionsToAddStyles(
    matchNumber: number,
    sections: Sections | Components,
    regexToRemove: RegExp,
    styleToAdd: string
  ) {
    if (matchNumber == this.selectedSectionIndex[1]) {
      const compressedSpacesRegex: RegExp = /\s{2,}/g;
      sections.styles = sections.styles.replace(regexToRemove, '');
      sections.styles = sections.styles + ' ' + styleToAdd;
      // remove double spaces
      sections.styles = sections.styles.replace(compressedSpacesRegex, ' ');
      const returnData = { matchNumber: matchNumber, flag: true };
      return returnData;
    }
    for (let i = 0; i < sections.components.length; i++) {
      if (sections.components[i].type == 'section') {
        matchNumber++;
        var returnedData = this.recersiveNumbersForSectionsToAddStyles(
          matchNumber,
          sections.components[i],
          regexToRemove,
          styleToAdd
        );
        matchNumber = returnedData.matchNumber;
        if (returnedData.flag) {
          const returnData = { matchNumber: matchNumber, flag: true };
          return returnData;
        }
      }
    }
    const returnData = { matchNumber: matchNumber, flag: false };
    return returnData;
  }
  // end of select section
  // check the style is presnt or no
  sectionStyleIncludes(styleValueToCheck: string) {
    return this.selectedSection?.styles.includes(styleValueToCheck);
  }
  // regression to add styles end
  //? end of styling
  //? start of adding attributes to components
  changeSelectedComponentStyle() {
    //! this is for sample that we can change -- re be removed not required - only for testing
    this.selectedComponent.styles = 'bg-black';
  }
  //? end of adding aatributes to components

  //? deletion and shifting of section and components

  // start of section delete and shift
  changeSectionUpByOne() {
    if (this.selectedSectionIndexInParent == -1) {
      [
        this.uiSharedService.selectedPage.sections[
          this.selectedSectionIndex[0] - 1
        ],
        this.uiSharedService.selectedPage.sections[
          this.selectedSectionIndex[0]
        ],
      ] = [
        this.uiSharedService.selectedPage.sections[
          this.selectedSectionIndex[0]
        ],
        this.uiSharedService.selectedPage.sections[
          this.selectedSectionIndex[0] - 1
        ],
      ];
      this.selectedSectionIndex[0] = this.selectedSectionIndex[0] - 1;
    } else {
      if (
        this.selectedParentSection.components[
          this.selectedSectionIndexInParent - 1
        ].type == 'section'
      ) {
        this.selectedSectionIndex[1] =
          this.selectedSectionIndex[1] -
          1 -
          this.getNumberOfNestedSections(
            this.selectedParentSection.components[
              this.selectedSectionIndexInParent - 1
            ]
          );
      }
      [
        this.selectedParentSection.components[
          this.selectedSectionIndexInParent - 1
        ],
        this.selectedParentSection.components[
          this.selectedSectionIndexInParent
        ],
      ] = [
        this.selectedParentSection.components[
          this.selectedSectionIndexInParent
        ],
        this.selectedParentSection.components[
          this.selectedSectionIndexInParent - 1
        ],
      ];

      this.selectedSectionIndexInParent = this.selectedSectionIndexInParent - 1;
    }
    this.onlyIndexChangeOfComponentAndSectionFlag = true;
    // ! setTimeOut has to be rmeoved and another approuch has to be implemented
    this.leftNavReloadFlag = !this.leftNavReloadFlag;
    this.componentReloadFlag = !this.componentReloadFlag;
    setTimeout(() => {
      this.leftNavReloadFlag = !this.leftNavReloadFlag;
      this.componentReloadFlag = !this.componentReloadFlag;
    }, 100);
    setTimeout(() => {
      this.createComponentBasedOnConfig();
    }, 200);
  }
  changeSectionDownByOne() {
    if (this.selectedSectionIndexInParent == -1) {
      [
        this.uiSharedService.selectedPage.sections[
          this.selectedSectionIndex[0] + 1
        ],
        this.uiSharedService.selectedPage.sections[
          this.selectedSectionIndex[0]
        ],
      ] = [
        this.uiSharedService.selectedPage.sections[
          this.selectedSectionIndex[0]
        ],
        this.uiSharedService.selectedPage.sections[
          this.selectedSectionIndex[0] + 1
        ],
      ];
      this.selectedSectionIndex[0] = this.selectedSectionIndex[0] + 1;
    } else {
      if (
        this.selectedParentSection.components[
          this.selectedSectionIndexInParent + 1
        ].type == 'section'
      ) {
        this.selectedSectionIndex[1] =
          this.selectedSectionIndex[1] +
          1 +
          this.getNumberOfNestedSections(
            this.selectedParentSection.components[
              this.selectedSectionIndexInParent + 1
            ]
          );
      }
      [
        this.selectedParentSection.components[
          this.selectedSectionIndexInParent + 1
        ],
        this.selectedParentSection.components[
          this.selectedSectionIndexInParent
        ],
      ] = [
        this.selectedParentSection.components[
          this.selectedSectionIndexInParent
        ],
        this.selectedParentSection.components[
          this.selectedSectionIndexInParent + 1
        ],
      ];

      this.selectedSectionIndexInParent = this.selectedSectionIndexInParent + 1;
    }
    this.onlyIndexChangeOfComponentAndSectionFlag = true;
    this.leftNavComponantsShowFlag = !this.leftNavComponantsShowFlag;
    // ! setTimeOut has to be rmeoved and another approuch has to be implemented
    this.leftNavReloadFlag = !this.leftNavReloadFlag;
    this.componentReloadFlag = !this.componentReloadFlag;
    setTimeout(() => {
      this.leftNavReloadFlag = !this.leftNavReloadFlag;
      this.componentReloadFlag = !this.componentReloadFlag;
    }, 100);
    setTimeout(() => {
      this.createComponentBasedOnConfig();
    }, 200);
  }
  deleteSelectedSection() {
    if (this.selectedSectionIndexInParent == -1) {
      this.uiSharedService.selectedPage.sections.splice(
        this.selectedSectionIndex[0],
        1
      );
    } else {
      this.selectedParentSection.components.splice(
        this.selectedSectionIndexInParent,
        1
      );
    }
    this.selectedSectionIndex[1] = -1;
    // ! setTimeOut has to be rmeoved and another approuch has to be implemented
    this.leftNavReloadFlag = !this.leftNavReloadFlag;
    this.componentReloadFlag = !this.componentReloadFlag;
    setTimeout(() => {
      this.leftNavReloadFlag = !this.leftNavReloadFlag;
      this.componentReloadFlag = !this.componentReloadFlag;
    }, 100);
    setTimeout(() => {
      this.createComponentBasedOnConfig();
    }, 200);
  }

  getNumberOfNestedSections(section: Components) {
    var index = 0;
    for (let i = 0; i < section.components.length; i++) {
      if (section.components[i].type == 'section') {
        index++;
        index = index + this.getNumberOfNestedSections(section.components[i]);
      }
    }
    return index;
  }
  // end of section delete and shift
  // start of component delete and shift
  changeComponentUpByOne() {
    if (
      this.selectedSection.components[this.selectedComponentIndexInParent - 1]
        .type == 'section'
    ) {
      this.selectedComponentIndex =
        this.selectedComponentIndex -
        this.getNumberOfNestedComponents(
          this.selectedSection.components[
            this.selectedComponentIndexInParent - 1
          ]
        );
    } else {
      this.selectedComponentIndex = this.selectedComponentIndex - 1;
    }

    [
      this.selectedSection.components[this.selectedComponentIndexInParent - 1],
      this.selectedSection.components[this.selectedComponentIndexInParent],
    ] = [
      this.selectedSection.components[this.selectedComponentIndexInParent],
      this.selectedSection.components[this.selectedComponentIndexInParent - 1],
    ];
    this.selectedComponentIndexInParent =
      this.selectedComponentIndexInParent - 1;
    this.onlyIndexChangeOfComponentAndSectionFlag = true;
    // ! setTimeOut has to be rmeoved and another approuch has to be implemented
    this.leftNavReloadFlag = !this.leftNavReloadFlag;
    this.componentReloadFlag = !this.componentReloadFlag;
    setTimeout(() => {
      this.leftNavReloadFlag = !this.leftNavReloadFlag;
      this.componentReloadFlag = !this.componentReloadFlag;
    }, 100);
    setTimeout(() => {
      this.createComponentBasedOnConfig();
      this.selectedComponentIndexChanged('');
    }, 200);
  }
  changeComponentDownByOne() {
    if (
      this.selectedSection.components[this.selectedComponentIndexInParent + 1]
        .type == 'section'
    ) {
      this.selectedComponentIndex =
        this.selectedComponentIndex +
        this.getNumberOfNestedComponents(
          this.selectedSection.components[
            this.selectedComponentIndexInParent + 1
          ]
        );
    } else {
      this.selectedComponentIndex = this.selectedComponentIndex + 1;
    }

    [
      this.selectedSection.components[this.selectedComponentIndexInParent + 1],
      this.selectedSection.components[this.selectedComponentIndexInParent],
    ] = [
      this.selectedSection.components[this.selectedComponentIndexInParent],
      this.selectedSection.components[this.selectedComponentIndexInParent + 1],
    ];
    this.selectedComponentIndexInParent =
      this.selectedComponentIndexInParent + 1;
    this.onlyIndexChangeOfComponentAndSectionFlag = true;
    // ! setTimeOut has to be rmeoved and another approuch has to be implemented
    this.leftNavReloadFlag = !this.leftNavReloadFlag;
    this.componentReloadFlag = !this.componentReloadFlag;
    setTimeout(() => {
      this.leftNavReloadFlag = !this.leftNavReloadFlag;
      this.componentReloadFlag = !this.componentReloadFlag;
    }, 100);
    setTimeout(() => {
      this.createComponentBasedOnConfig();
      this.selectedComponentIndexChanged('');
    }, 200);
  }
  deleteSelectedComponent() {
    this.selectedSection.components.splice(
      this.selectedComponentIndexInParent,
      1
    );
    this.selectedComponentIndex = -1;
    // ! setTimeOut has to be rmeoved and another approuch has to be implemented
    this.leftNavReloadFlag = !this.leftNavReloadFlag;
    this.componentReloadFlag = !this.componentReloadFlag;
    setTimeout(() => {
      this.leftNavReloadFlag = !this.leftNavReloadFlag;
      this.componentReloadFlag = !this.componentReloadFlag;
    }, 100);
    setTimeout(() => {
      this.createComponentBasedOnConfig();
      this.selectedComponentIndexChanged('');
    }, 200);
  }
  getNumberOfNestedComponents(section: Components) {
    var index = 0;
    for (let i = 0; i < section.components.length; i++) {
      if (section.components[i].type == 'section') {
        index = index + this.getNumberOfNestedComponents(section.components[i]);
      } else {
        index++;
      }
    }
    return index;
  }
  //? end deletion and shifting of section and components

  //? navigation function and custom inbuild finctions
  reinitialize(pageId: string) {
    // Navigate to the same route to reinitialize the component
    console.log('reinitialize');
    var flag = true;
    this.pageService.$values.pipe(take(1)).subscribe((value: any) => {
      for (let page of value) {
        if (page.id == pageId) {
          this.pageService.selectedPage = page;
          flag = false;
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              // this.router.navigate([this.router.url]);
              this.router.navigate(['ui-editor']);
              console.log('bal');
            });
        }
      }
      if (flag) {
        alert('page navigation error:' + pageId + ' is not present');
      }
    });
  }

  //? for query in crud service
  async query(entity: any, conditions: any) {
    if (entity == 'NAN') {
      alert('entityID Issue');
    } else {
      var dataReturned: any;
      await this.crudService
        .queryEntity(entity, conditions)
        .then((data: any) => {
          dataReturned = data.data;
        });

      return dataReturned[Object.keys(dataReturned)[0]];
    }
  }
  getEntityById(entityId: string) {
    var entityToBeReturned: Entity = {
      id: '',
      name: '',
      type: '',
      status: 'not_found',
      data: [],
      roles: [],
    };
    var returnedData: TypeData = {
      name: '',
      type: '',
      isUnique: false,
      primaryKey: false,
      startIncrementFrom: 0,
      referenceEntityName: '',
      referenceEntityColumnName: '',
      id: 'not_found',
      data: [],
    };
    for (let entity of this.pageService.newEntityListForSelected) {
      if (entity.id == entityId) {
        return entity;
      }
      returnedData = this.recursivelyGetEntityById(entity.data, entityId);
      if (returnedData.id !== 'not_found') {
        entityToBeReturned.id = returnedData.id;
        entityToBeReturned.name = returnedData.name;
        entityToBeReturned.type = returnedData.type;
        entityToBeReturned.status = 'FINALIZED';
        entityToBeReturned.data = returnedData.data;
        return entityToBeReturned;
      }
    }
    return entityToBeReturned;
  }
  recursivelyGetEntityById(entity: TypeData[], entityId: string) {
    var data: TypeData = {
      name: '',
      type: '',
      isUnique: false,
      primaryKey: false,
      startIncrementFrom: 0,
      referenceEntityName: '',
      referenceEntityColumnName: '',
      id: 'not_found',
      data: [],
    };
    for (let variable of entity) {
      if (variable.type == 'ARRAY_OF_OBJECT') {
        if (variable.id == entityId) {
          return variable;
        }
        this.recursivelyGetEntityById(variable.data, entityId);
      }
    }

    return data;
  }
  //? Inserting an entity data into db
  async createEntity(entity: Entity, data: any) {
    console.log('create madhukar');
    console.log(entity);
    console.log(data);
    if (entity.status !== 'not_found') {
    } else {
      alert('problem with the insert new data some entity has bee changed ');
    }
  }
  async updateEntity(entity: Entity, entityId: Entity, data: any) {
    console.log('update madhukar');
    console.log(entity);
    console.log(entityId);
    console.log(data);
  }
}
