import { Injectable } from '@angular/core';
import { BaseRepositoryService } from './base.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariablesService extends BaseRepositoryService<any>{

  override collectionPath: string = '/globalVaraible';
  globalVariable: any = {};
  constructor(private authService: AuthService) {
    super();
    this.collectionPath = this.authService.baseCollectionPath + this.collectionPath;
    this.initialize();
   }

  async initialize() {

    await this.query(1000);
    this.$values.subscribe((value: any) => {
if(value.length) {
  this.globalVariable = value[0];
} else {
  this.create({id:'globalVariable'});
}
    })
  }
}
