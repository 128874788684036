<div class="h-screen w-screen">
  <form class="flex flex-col h-full justify-center items-center gap-6">
    <h1 class="text-2xl font-semibold">Log In</h1>
    <div class="flex flex-col gap-1">
      <label class="text-[13px] ml-1 font-medium" for="email">Email</label>
      <input
        [(ngModel)]="email"
        required
        class="px-2 h-[40px] w-[390px] border rounded-md"
        type="email"
        id="email"
        name="email"
      />
    </div>
    <div class="flex flex-col gap-1">
      <label class="text-[13px] ml-1 font-medium" for="email">Password</label>
      <input
        [(ngModel)]="password"
        required
        class="px-2 h-[40px] w-[390px] border rounded-md"
        type="password"
        id="password"
        name="password"
      />
    </div>
    <button
      (click)="login()"
      type="submit"
      class="bg-[#216AD3] rounded-md text-white h-[40px] w-[390px] flex flex-col justify-center items-center"
    >
      Login
    </button>
  </form>
</div>
